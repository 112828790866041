<template>
  <form
    class="image-form p-2"
    @submit.prevent="handleSubmit"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <div class="p-2">
      <div class="row mr-2">
        <select-input
          class="col-6"
          label="Twain Device"
          :items="sourceList"
          displayExpr="displayName"
          valueExpr="id"
          name="twainDevice"
          v-model="selectedSource"
        />
        <select-input
          class="col-6"
          label="Image Resolution"
          :items="resolutions"
          displayExpr="displayName"
          valueExpr="id"
          name="resolution"
          v-model="selectedResolution"
        />
        <select-input
          class="col-3"
          label="Use Feeder"
          :items="yesOrNo"
          displayExpr="displayName"
          valueExpr="id"
          name="useScannerFeeder"
          v-model="useScannerFeeder"
        />
        <select-input
          v-if="useScannerFeeder"
          class="col-3"
          label="Double Sided"
          :items="yesOrNo"
          displayExpr="displayName"
          valueExpr="id"
          name="useDuplex"
          v-model="useDuplex"
        />
        <div class="col-3" v-else />
        <select-input
          class="col-6"
          label="Close After Scan"
          :items="yesOrNo"
          displayExpr="displayName"
          valueExpr="id"
          name="closeAfterTwain"
          v-model="closeAfterTwain"
        />
      </div>
    </div>
    <icon-button
      class="btn-outline-primary capture-btn mx-1"
      icon="camera-retro"
      v-shortkey="shortkeyTake"
      @shortkey="acquireImage"
      @click="acquireImage"
    >
      <span class="ml-1">Star<u>t</u> Scanning</span>
    </icon-button>
    <div class="progress mb-2" v-show="isLoading">
      <div
        class="progress-bar progress-bar-striped bg-success progress-bar-animated"
        role="progressbar"
        :style="uploadStatus$"
        :aria-valuenow="uploadStatus$.progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ uploadStatus$.progress }}
      </div>
    </div>
    <div v-if="hasMultipleImages" class="d-flex justify-content-between">
      <h2>Image<span v-if="useMultiUpload">s</span></h2>
      <select-input
        v-if="imageCount > 1"
        :value="twainMultiUploadType"
        @input="updateMultiType"
        label="Upload Type"
        :items="multiUploadOptions"
        name="twainMultiUploadType"
      />
      <div v-else />
    </div>
    <div v-if="useMultiUpload">
      <div v-for="(image, idx) of imagesData" v-bind:key="image.imageId">
        <TWAINItems
          v-model="imagesData[idx]"
          :isOperating="isOperating"
          :hasMultipleImages="hasMultipleImages"
          :multiUpload="true"
          :imageCount="imageCount"
          :ref="'item' + image.imageId"
          :imageTypes="imageTypes"
          :tagOptions="tagOptions"
        />
      </div>
    </div>
    <div v-else-if="imageCount">
      <TWAINItems
        v-model="imagesData[0]"
        :isOperating="isOperating"
        :hasMultipleImages="hasMultipleImages"
        :imageCount="imageCount"
        :ref="'item' + imagesData[0].imageId"
        :imageTypes="imageTypes"
        :tagOptions="tagOptions"
      />
    </div>
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn-primary btn save-btn">Save</button>
    </div>
  </form>
</template>

<script>
import { DropdownApi, ImagesApi } from "@/services";
import SelectInput from "./common/SelectInput.vue";
import { mapState } from "vuex";
import { altKey, booleanLookup } from "@/modules/helpers";
import Dynamsoft from "dwt";
import IconButton from "./common/IconButton.vue";
import { WEB_TWAIN_ID } from "@/modules/constants";
import {
  fromBusEvent,
  SCANNED_IMAGE,
  TWAIN_OPERATION_STARTS,
  TWAIN_OPERATION_ENDS,
  UPLOAD_PROGRESS,
  SCANNED_ALL_IMAGE
} from "@/modules/eventBus";
import { distinctUntilChanged, map, startWith, filter, tap } from "rxjs/operators";
import TWAINDriver from "@/modules/TWAINDriver";
import { enumToDropDown, ImageFileTypeEnum } from "../modules/enums";
import TWAINItems from "@/components/TWAINItems.vue";

export default {
  components: {
    SelectInput,
    IconButton,
    TWAINItems
  },
  props: ["caseId", "specimenId"],
  data() {
    return {
      imagePath: null,
      imageData: {
        specimenId: null,
        imageTypeId: null,
        imagePath: "",
        keywords: "",
        printOnReport: false,
        tagIds: [],
        comment: ""
      },
      DWObject: null,
      sourceList: [],
      selectedSource: null,
      closeAfterTwain: 0,
      useScannerFeeder: 0,
      selectedResolution: 0,
      hasMultipleImages: false,
      isOperating: false,
      yesOrNo: [
        { displayName: "Yes", id: 1 },
        { displayName: "No", id: 0 }
      ],
      containerId: "twain-container",
      fileType: "image/jpeg",
      isScanning: false,
      imageTypes: [],
      isLoading: false,
      tagOptions: [],
      scannerSources: [],
      imageIdx: 0,
      imageCount: 0,
      fileName: "",
      booleanOptions: booleanLookup.dataSource,
      saveShortkey: altKey("s"),
      shortkeyTake: altKey("t"),
      resolutions: [
        { id: 0, displayName: "Use Device" },
        { id: 1980, displayName: "1080 HD" },
        { id: 720, displayName: "720 HD" },
        { id: 480, displayName: "480 SD" }
      ],
      fileExtensionOptions: [
        { id: 0, displayName: "png" },
        { id: 1, displayName: "pdf" },
        { id: 2, displayName: "tif" }
      ],
      multiUploadOptions: [
        { id: 0, displayName: "Single Image" },
        { id: 1, displayName: "Multiple Images" }
      ],
      imagesData: [],
      useDuplex: 0
    };
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.specimens,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      defaultImageType: state => state.applicationSettings.defaultImageType,
      imagePrintOnReport: state => state.applicationSettings.imagePrintOnReport,
      caseDetails: state => state.accessionStore.caseDetails,
      savedSelectedResolution: state => state.applicationSettings.selectedResolution,
      savedCloseAfterTwain: state => state.applicationSettings.closeAfterTwain,
      selectedTWAINDevice: state => state.applicationSettings.selectedTWAINDevice,
      savedUseScannerFeeder: state => state.applicationSettings.useScannerFeeder,
      savedDuplexScanning: state => state.applicationSettings.useDuplexScanning,
      twainMultiUploadType: state => state.applicationSettings.twainMultiUploadType
    }),
    isPdf() {
      return /pdf/i.test(this.fileType);
    },
    useMultiUpload() {
      return this.imageCount > 1 && this.twainMultiUploadType;
    }
  },

  mounted() {
    this.startTwain();
    if (this.savedSelectedResolution) {
      this.selectedResolution = this.savedSelectedResolution;
    }
    if (this.savedCloseAfterTwain) {
      this.closeAfterTwain = this.savedCloseAfterTwain ? 1 : 0;
    }
    if (this.savedUseScannerFeeder) {
      this.useScannerFeeder = this.savedUseScannerFeeder ? 1 : 0;
      this.useDuplex = this.savedDuplexScanning ? 1 : 0;
    }
    DropdownApi.getTags().then(res => {
      this.tagOptions = res;
    });
    ImagesApi.getImageTypes().then(imageTypes => {
      this.imageTypes = imageTypes || [];
    });
  },
  subscriptions() {
    const uploadStatus$ = fromBusEvent(UPLOAD_PROGRESS).pipe(
      filter(() => this.isLoading),
      startWith({ loaded: 0, total: 100 }),
      map(progressEvent => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      distinctUntilChanged(),
      map(progress => ({
        width: `${progress}%`,
        progress: progress
      }))
    );
    const imageReceived$ = fromBusEvent(SCANNED_IMAGE).pipe(
      map(data => {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        const { imageId } = data;
        this.imagesData.push({
          specimenId: this.currentSpecimen?.id || null,
          imageTypeId: this.defaultImageType || null,
          imagePath: "",
          keywords: "",
          printOnReport: this.imagePrintOnReport || false,
          tagIds: [],
          comment: "",
          imageId,
          imageIndex: DWObject.ImageIDToIndex(imageId),
          fileExtension: 0
        });
        this.hasMultipleImages = DWObject.HowManyImagesInBuffer > 1;
      })
    );
    const allTransfers$ = fromBusEvent(SCANNED_ALL_IMAGE).pipe(
      map(() => {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        this.hasMultipleImages = DWObject.HowManyImagesInBuffer > 1;
        this.imageCount = DWObject.HowManyImagesInBuffer;
        this.getSources();
      })
    );

    const isOperating = fromBusEvent(TWAIN_OPERATION_STARTS).pipe(
      tap(() => (this.isOperating = true))
    );
    const isNotOperating = fromBusEvent(TWAIN_OPERATION_ENDS).pipe(
      tap(() => (this.isOperating = false))
    );

    return {
      isOperating,
      isNotOperating,
      uploadStatus$,
      imageReceived$,
      allTransfers$
    };
  },
  watch: {
    specimenId: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.imagesData = this.imagesData.map(e => {
            return { ...e, specimenId: nv };
          });
        }
      }
    },
    selectedSource(nv) {
      this.$store.commit("applicationSettings/setSelectedTWAINDevice", nv);
    },
    selectedResolution(nv) {
      this.$store.commit("applicationSettings/setSelectedResolution", nv);
    },
    closeAfterTwain(nv) {
      this.$store.commit("applicationSettings/setCloseAfterTwain", nv);
    },
    useScannerFeeder(nv) {
      this.$store.commit("applicationSettings/setUseScannerFeeder", nv);
    },
    useDuplex(nv) {
      this.$store.commit("applicationSettings/setScannerDuplex", nv);
    },
    imagesData: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        if (!this.useMultiUpload) {
          for (let i; i < nv.length; i++) {
            if (nv[i]?.fileExtension !== ov[i].fileExtension) {
              this.imagesData = this.imagesData.map(e => {
                return { ...e, fileExtension: nv[i].fileExtension };
              });
              break;
            }
          }
        }
      }
    }
  },
  methods: {
    disconnectTwain() {
      Dynamsoft.DWT.DeleteDWTObject(WEB_TWAIN_ID);
    },
    handlePrevious() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.previous();
    },
    handleNext() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      this.imageIdx = DWObject.Viewer.next();
    },
    async getImageData(imagePath) {
      const imageFetch = await fetch(imagePath);
      const imageBlob = await imageFetch.blob();

      return imageBlob;
    },
    async startTwain() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      if (DWObject) {
        const element = this.$refs.imagePreview;
        DWObject.Viewer.bind(element);
        DWObject.Viewer.width = "100%";
        DWObject.Viewer.height = 400;
        DWObject.Viewer.singlePageMode = true;
        DWObject.Viewer.show();
        this.getSources();
        this.hasMultipleImages = DWObject.HowManyImagesInBuffer > 1;
      }
    },
    async getSources() {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      let tryCount = 0;
      while (!DWObject.SourceCount && tryCount < 3) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        tryCount++;
      }
      const sources = DWObject.GetSourceNames();
      this.sourceList = sources.map((e, idx) => ({ displayName: e, id: idx }));
      if (Number.isInteger(this.selectedTWAINDevice)) {
        const findInList = DWObject.GetSourceNameItems(this.selectedTWAINDevice);
        if (findInList) {
          this.selectedSource = this.selectedTWAINDevice;
        }
      }
    },
    async acquireImage() {
      TWAINDriver.acquireImage(this.selectedSource);
    },
    convertToFileType(imageId, fileType = ImageFileTypeEnum.PDF) {
      const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
      const imageCount = DWObject.HowManyImagesInBuffer;
      const imageIndexes =
        imageId != null ? [imageId] : Array.from({ length: imageCount }, (_, i) => i);
      return new Promise((resolve, reject) => {
        const enumLookup = "IT_" + this.getFileExtensionNameFromId(fileType).toUpperCase();
        DWObject.ConvertToBlob(
          imageIndexes,
          Dynamsoft.DWT.EnumDWT_ImageType[enumLookup],
          (result, indices, type) => {
            resolve({
              type,
              result
            });
          },
          (errorCode, error) => {
            console.log("Error Code", errorCode);
            reject(error);
          }
        );
      });
    },

    async handleSubmit() {
      for (const image of this.imagesData) {
        let ref = this.$refs["item" + image.imageId];
        if (ref) {
          if (Array.isArray(ref)) {
            ref = ref[0];
          }
          ref.$v.$touch();
          if (ref.$v.$invalid) {
            window.notify("Please verify your input.", "warning");
            return;
          }
        }
      }
      try {
        this.isLoading = true;
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        if (!this.imagesData.length) {
          window.notify("Please scan an image.", "warning");
          return;
        }
        if (!this.useMultiUpload) {
          this.imagesData = [this.imagesData[0]];
        }
        for (const imageData of this.imagesData) {
          const formData = new FormData();
          const {
            imageTypeId,
            tagIds,
            specimenId,
            keywords,
            printOnReport,
            comment,
            imageIndex,
            fileExtension
          } = imageData;
          if (this.hasMultipleImages && !this.useMultiUpload) {
            const response = await this.convertToFileType(null, fileExtension);
            const { result } = response;
            const todayString = new Date().toISOString().split("T")[0];
            formData.append(
              "file",
              result,
              `multi-scan-${todayString}.${this.getFileExtensionNameFromId(
                fileExtension
              ).toLowerCase()}`
            );
          } else {
            let file = null;
            if (fileExtension > 0) {
              const response = await this.convertToFileType(imageIndex, fileExtension);
              file = response.result;
            } else {
              let imageUrl = "";
              if (this.selectedResolution > 0 && this.selectedResolution) {
                imageUrl = DWObject.GetImageURL(imageIndex, this.selectedResolution);
              } else {
                imageUrl = DWObject.GetImageURL(imageIndex);
              }
              file = await this.getImageData(imageUrl);
            }
            formData.append(`file`, file);
          }
          formData.append(
            "jsonPayload",
            JSON.stringify({
              imageTypeId,
              tagIds,
              specimenId,
              keywords,
              printOnReport,
              comment,
              caseId: this.caseDetails.caseId
            })
          );
          await this.$store.dispatch("accessionStore/insertImage", formData);
        }
        const imageCount = DWObject.HowManyImagesInBuffer;
        if (imageCount > 0) {
          DWObject.RemoveAllImages();
        }
        window.notify("Image uploaded successfully!");
        this.$emit("close");
      } catch (error) {
        window.notify("An error occured.", "error");
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    getBLOBFileHeader(blob) {
      return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onloadend = function (e) {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = "";
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          resolve(header);
        };
        fileReader.readAsArrayBuffer(blob);
      });
    },
    // Add more from http://en.wikipedia.org/wiki/List_of_file_signatures
    mimeType(headerString) {
      let type = "unknown";
      switch (headerString) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          type = "image/jpeg";
          break;
        case "25504446":
          type = "application/pdf";
          break;
        default:
          type = "unknown";
          break;
      }
      return type;
    },
    updateMultiType(value) {
      this.$store.commit("applicationSettings/setTwainMultiUploadType", value);
    },
    getFileExtensionNameFromId(id) {
      const enumList = enumToDropDown(ImageFileTypeEnum);
      return enumList[id].displayName;
    }
  }
};
</script>
<style lang="scss" scoped>
.image-form {
  max-width: 40vw;
}
::v-deep .image-preview {
  width: 100%;
  height: 300px;
  padding: 1rem 0 0 0;
}
</style>
