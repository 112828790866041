import { PathReportLocationsEnum, PermissionsEnum, UserTypesEnum } from "@/modules/enums";
import supportsPDF from "@/modules/supportsPdf";
import { CasesApi, ReportsApi } from "../services/index";
import store from "@/store";

export const report = {
  namespaced: true,
  state: () => ({
    isOpen: false,
    caseId: null,
    loading: false,
    reportUrl: null,
    caseStatus: null
  }),
  mutations: {
    setReport(state, payload) {
      state.report = payload;
    },
    toggleReportViewer(state, payload) {
      state.isOpen = payload;
    },
    setReportFile(state, payload) {
      state.reportFile = payload;
    },
    setCaseId(state, payload) {
      state.caseId = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setReportUrl(state, payload) {
      state.reportUrl = payload;
    },
    setCaseStatus(state, payload) {
      state.caseStatus = payload;
    }
  },
  getters: {
    isWindowed(state, getters, rootState) {
      if (rootState.currentUser.userTypeId === UserTypesEnum.LabClientUser) {
        return state.isOpen && rootState.currentUser.pathReportPreviewLocationId === 1;
      }
      return state.isOpen && rootState.applicationSettings.pathReportLocation === 1;
    },
    isInline(state, getters, rootState) {
      if (rootState.currentUser.userTypeId === UserTypesEnum.LabClientUser) {
        return state.isOpen && rootState.currentUser.pathReportPreviewLocationId === 0;
      }
      if (rootState.isMobileView) {
        return true;
      }
      if (rootState.currentUser.userTypeId === UserTypesEnum.LabClientUser) {
        return state.isOpen && rootState.currentUser.pathReportPreviewLocationId === 0;
      }
      return state.isOpen && rootState.applicationSettings.pathReportLocation === 0;
    },
    supportsPDF() {
      return supportsPDF();
    }
  },
  actions: {
    clearReport({ commit }) {
      commit("setCaseId", null);
      commit("toggleReportViewer", false);
      commit("setReportUrl", null);
    },
    async viewPathReport({ commit, dispatch, rootState, state }, { caseId, startPage }) {
      if (!caseId) {
        console.log("Tried to load path report with null caseId.");
        return;
      }
      try {
        if (
          rootState.applicationSettings.pathReportLocation === PathReportLocationsEnum.None &&
          !rootState.isMobileView
        ) {
          commit("setLoading", false);
          return;
        }
        if (typeof caseId === "object") {
          console.log("Error occurred somehow case id is an object?", caseId);
          if (caseId.caseId) {
            commit("setCaseId", caseId.caseId);
          }
          caseId = caseId.caseId;
        } else {
          commit("setCaseId", caseId);
        }
        commit("setLoading", true);
        if (caseId) {
          const status = await CasesApi.getCaseStatus(caseId);
          commit("setCaseStatus", status);
        }
        const supportsPDFEmbed = supportsPDF();
        const isClientUser = rootState.currentUser.userTypeId === UserTypesEnum.LabClientUser;
        const report = isClientUser
          ? await CasesApi.getMultiCasePdf([parseInt(caseId)])
          : await ReportsApi.getSSRSReport({
              caseId,
              format: supportsPDFEmbed ? "pdf" : "IMAGE",
              outputFormat: supportsPDFEmbed ? null : "JPEG",
              StartPage: startPage ?? 0
            });
        const windowUrl = window.location.href;
        if (/accession\/\d+/i.test(windowUrl)) {
          const reportIdRegex = windowUrl.match(/(?<=accession\/)\d+/i);
          if (reportIdRegex[0] !== caseId + "") {
            return;
          }
        }
        const blob = new Blob([report], {
          type: supportsPDFEmbed ? "application/pdf" : "image/jpeg"
        });
        const url = URL.createObjectURL(blob);
        commit("setReportUrl", url);
        if (!state.isOpen) {
          commit("toggleReportViewer", true);
        }

        return report;
      } catch (error) {
        if (
          error?.response?.status === 401 &&
          !store.state.currentUser.distinctPermissions.includes(PermissionsEnum.PathReportView)
        ) {
          window.notify("User does not have permission to view path report.", "error");
        } else {
          window.notify("Error generating path report.", "error");
        }
        dispatch("clearReport");
      } finally {
        commit("setLoading", false);
      }
      return false;
    }
  }
};
