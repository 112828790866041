<template>
  <div class="macro_popup">
    <form
      @submit.prevent="handleSubmit"
      class="container"
      v-shortkey="shortkeys"
      @shortkey.prevent="handleShortkey"
    >
      <div class="d-flex justify-content-between title">
        <div>
          <h3>{{ macroType }}</h3>
          <p :class="getTextClass('Clinical')" v-show="currentSpecimenClinical">
            <b class="mr-2 mb-0">Clinical:</b>{{ currentSpecimenClinical }}
          </p>
          <p :class="getTextClass('Gross')" v-show="currentSpecimenGross">
            <b class="mr-2 mt-0">Gross:</b>{{ currentSpecimenGross }}
          </p>
        </div>
        <div class="d-flex flex-column align-items-end font-weight-bold target_title">
          <div class="d-flex mb-1">
            <span
              v-tooltip="'Click to view case history.'"
              v-if="historyCount"
              class="btn btn-danger mr-1"
              @click="toggleHistory"
            >
              <u>H</u>istory
            </span>
            <button
              v-if="caseImages.length"
              :class="`btn btn-${hasImagesOnReport ? 'success' : 'warning'} mr-3`"
              @click.prevent="toggleImagesPopup"
            >
              <u>I</u>mages
            </button>
            <button
              v-if="showSlidesButton"
              class="btn btn-success mr-3"
              @click.prevent="launchSlideImages(target.id)"
            >
              Slides
            </button>
            <span class="font-bold large-case-number">{{ shortenedCaseNumber }}</span>
          </div>
          <div class="d-flex mb-1 large-header-text">
            <span class="font-bold border-right mx-1 pr-2">
              <span data="private=redact"
                >{{ caseDetails.patientLastName }}, {{ caseDetails.patientFirstName }}</span
              >
              <span v-if="caseDetails.patientSex != null">
                ({{ genders[caseDetails.patientSex] }})
              </span>
            </span>
            <span class="font-regular ml-1"
              >DOB: <span data-private="redact">{{ patientDOB }} ({{ patientAge }})</span></span
            >
          </div>
          <div class="d-flex flex-column align-items-end font-weight-bold target_title">
            <div class="d-flex mb-1">
              <span
                v-if="caseDetails.patientMRN"
                :class="`font-bold ${lastNumberInRow('patientMrn')}`"
                >MRN: <span data-private="redact">{{ caseDetails.patientMRN }}</span></span
              >
              <span
                v-if="caseDetails.acctNumber"
                :class="`font-bold ${lastNumberInRow('acctNumber')}`"
                >ACCT# <span data-private="redact">{{ caseDetails.acctNumber }}</span></span
              >
              <span
                v-if="caseDetails.orderNumber"
                :class="`font-bold ${lastNumberInRow('orderNumber')}`"
                >ORDER #: {{ caseDetails.orderNumber }}</span
              >
            </div>
          </div>
          <div v-html="targetTitle" class="mb-1 large-header-text"></div>
          <div class="d-flex flex-column align-items-end font-weight-bold target_title">
            Primary Provider: {{ primaryProviderAndLocation }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <p v-if="primaryPathologistName && !pathologistGuid" class="pathologist-name">
          <b>Primary Pathologist:</b> {{ primaryPathologistName }}
        </p>
        <div v-else />
        <TextInput
          placeholder="Exact Match"
          id="exact-match"
          class="exact-match mr-2"
          v-model="exactMatchText"
          ref="exactMatch"
          autocomplete="off"
        />
      </div>
      <dx-grid-with-search
        class="grid"
        :gridName="gridName"
        :dataSource="dataSource"
        :columns="columns"
        v-stream:content-ready="contentReady$"
        @initialized="initGrid"
        :selection="selection"
        :scrolling="scrolling"
        v-stream:selection-changed="selectionChange$"
        :searchPanel="searchPanel"
        :toolbar="toolbar"
        :onEditorPreparing="onEditorPreparing"
        :onCellDblClick="handleSubmit"
        :onFocusedCellChanged="onFocusedCellChanged"
        keyExpr="macroId"
      >
        <template v-slot:actions="{ data }">
          <div>
            <icon-button
              :disabled="!hasEditPermission"
              type="button"
              class="btn p-0 text-primary mx-1"
              @click="editMacro(data)"
              v-tooltip="'Edit this macro.'"
              icon="pen-alt"
              :data-testid="`edit-macro-${data.rowIndex}`"
            />
            <icon-button
              :disabled="!hasEditPermission"
              type="button"
              class="btn p-0 text-primary mx-1"
              v-tooltip="'Clone this macro.'"
              icon="copy"
              @click="cloneMacro(data)"
              :data-testid="`clone-macro-${data.rowIndex}`"
            />
          </div>
        </template>
        <template v-slot:searchType>
          <DxSwitch
            slot="searchType"
            v-tooltip="searchType ? 'Macro search' : 'Free text search'"
            switched-on-text="Macro"
            switched-off-text="Text"
            v-model="searchType"
            width="4rem"
          />
        </template>
        <template v-slot:startsWith>
          <DxSwitch
            v-if="searchType"
            slot="startsWith"
            v-tooltip="startsWith ? 'Macro Starts With' : 'Contains'"
            switched-on-text="Starts With"
            switched-off-text="Contains"
            v-model="startsWith"
            width="5rem"
          />
        </template>
      </dx-grid-with-search>
      <div v-if="selectedMacroId" :key="selectedMacroId" class="summary col mx-0 mt-2 p-0">
        <DxScrollView>
          <h4>Name: {{ selectedMacro.macroName }}</h4>
          <checkbox
            name="all"
            id="all"
            label="All"
            :value="allFieldsSelected"
            @input="handleSelectAllFields"
          />
          <div class="border-top pt-1" v-for="(field, index) in fieldMap" :key="field + index">
            <checkbox
              :name="field"
              :id="field + 'box'"
              :label="startCase(field)"
              v-model="targetFields[field]"
            />
            <div v-html="selectedMacro[field]" v-if="selectedMacro[field]"></div>
            <div class="text-muted" v-else>No data found.</div>
          </div>
        </DxScrollView>
      </div>

      <div class="d-flex align-self-end">
        <button class="btn btn-primary ml-auto" @click.prevent="handleSubmit">Submit</button>
      </div>
    </form>
    <modal :status="isManagementOpen" @close="handleCancelConfig">
      <div class="p-3">
        <configuration
          :isClone="isCloning"
          :selectedMacroId="selectedMacroId"
          @submit="handleConfigSubmit"
          @clear="handleCancelConfig"
        />
      </div>
    </modal>
    <modal :status="isHistoryOpen" @close="isHistoryOpen = false">
      <CaseHistory />
    </modal>
    <modal :status="isImagesOpen" @close="toggleImagesPopup">
      <QuickLinksImagePopup />
    </modal>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters, mapState } from "vuex";
import { fromEvent } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import moment from "moment";
import { startCase } from "lodash";
import { MacrosApi, DropdownApi } from "@/services";
import { DxSwitch } from "devextreme-vue";
import DataSource from "devextreme/data/data_source";
import eventBus, {
  CLOSE_SLIDE_IMAGES,
  OPEN_SLIDE_IMAGES,
  RESTORE_EDITOR_POSITION
} from "@/modules/eventBus";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import Modal from "./common/Modal.vue";
import Configuration from "./forms/Macros/Configuration.vue";
import IconButton from "./common/IconButton.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import { gridKeybindings } from "@/modules/gridKeybindings";
import {
  altKey,
  dateRangeFilter,
  getTextFromHtml,
  shortenAccessionNumber
} from "@/modules/helpers";
import {
  MacroTypeEnum,
  MacroWindowClinicalEnum,
  MacroWindowPrimaryTextEnum
} from "@/modules/enums";
import TextInput from "./common/TextInput.vue";
import CaseHistory from "@/components/CaseHistory.vue";
import QuickLinksImagePopup from "./QuickLinksImagePopup.vue";

export default {
  name: "MacroPopup",
  components: {
    DxSwitch,
    DxGridWithSearch,
    Checkbox,
    Modal,
    Configuration,
    IconButton,
    DxScrollView,
    TextInput,
    CaseHistory,
    QuickLinksImagePopup
  },
  data: () => ({
    isLoading: true,
    grid: {},
    toolbar: {
      items: [
        {
          location: "after",
          template: "searchType"
        },
        {
          location: "after",
          template: "startsWith"
        }
      ]
    },
    searchOutput: [],
    isManagementOpen: false,
    searchValue: "",
    diagnosisSummaries: [],
    isCloning: false,
    macroTypes: [],
    targetFields: {
      caseNotes: true,
      clinical: true,
      diagnosis: true,
      microscopic: true,
      specimenNotes: true,
      gross: true
    },
    selection: {
      mode: "single"
    },
    scrolling: {
      showScrollbarAlways: true,
      mode: "infinite",
      preloadEnabled: true,
      rowRenderingMode: "virtual"
    },
    remoteOperations: {
      filtering: true,
      sorting: true,
      paging: true,
      grouping: false
    },
    searchType: false,
    startsWith: false,
    selectedMacro: {},
    selectedIndex: [],
    protocolOptions: [],
    // procedureOptions: [],
    typeMap: {
      Results: [
        "users",
        "diagnosisSummaryId",
        "diagnosis",
        "interfaceDiagnosis",
        "microscopic",
        "specimenNotes",
        "caseNotes",
        "cptCodes",
        "holdCodes",
        "icdCodes"
      ],
      General: [
        "users",
        "generalText",
        "cptCodes",
        "holdCodes",
        "icdCodes",
        "procedures",
        "images",
        "tags"
      ]
    },
    genders: ["M", "F", "O", "U"],
    isListeningEnter: false,
    lastSearch: "",
    procedurePopupTextToReplace: "",
    exactMatchText: "",
    isHistoryOpen: false,
    macroIdToSelect: null,
    isImagesOpen: false,
    specimenTypeId: null
  }),
  props: {
    targetSpecimen: Object,
    targetType: Number,
    dialogFromWysiwyg: Boolean,
    procedureGeneralMacroInput: Object,
    pathologistGuid: String
  },
  created() {
    DropdownApi.getMacroTypes().then(res => {
      this.macroTypes = res || [];
    });
    DropdownApi.getProtocol().then(res => {
      this.protocolOptions = res || [];
    });
    this.$store.dispatch("dropdowns/getBodyParts");
    // DropdownApi.searchProcedures.load().then(res => {
    //   this.procedureOptions = res || [];
    // });
    DropdownApi.getDiagnosisSummaries(this.currentLab).then(res => {
      this.diagnosisSummaries = res || [];
    });
    this.searchType = this.$store.state.applicationSettings.macroSearchMode;
    this.startsWith = this.$store.state.applicationSettings.macroStartsWith;
  },
  mounted() {
    this.targetFields = this.fieldMap.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    this.handlePreloadFields(this.targetType);
    if (this.procedureGeneralMacroInput && Object.keys(this.procedureGeneralMacroInput).length) {
      this.procedurePopupTextToReplace = this.procedureGeneralMacroInput.input;
    }
    this.checkSpecimenType();
    eventBus.$on(CLOSE_SLIDE_IMAGES, this.handleCloseSlideImages);
  },
  beforeDestroy() {
    eventBus.$off(CLOSE_SLIDE_IMAGES);
  },
  domStreams: ["selectionChange$", "contentReady$", "submitClick$"],
  subscriptions() {
    // Event from the devExtreme grid.
    const gridReady$ = this.contentReady$.pipe(
      map(data => data.event.msg),
      tap(() => {
        if (this.macroIdToSelect) {
          this.grid.selectRows([this.macroIdToSelect]);
          this.macroIdToSelect = null;
        }
      })
    );
    const focusExactMatch$ = gridReady$.pipe(
      take(1),
      tap(() => this.focusExactMatch())
    );
    const keyDown$ = fromEvent(document.body, "keydown");
    const typeInSearchPanel$ = keyDown$.pipe(
      filter(({ target, altKey }) => {
        return !altKey && target.id === "searchPanel";
      })
    );
    const focusFirstRow$ = typeInSearchPanel$.pipe(
      switchMap(({ key }) =>
        gridReady$.pipe(
          filter(() => {
            // Only focus if the last key pressed was not an arrow key
            return !/Arrow/i.test(key);
          }),
          tap(async ({ component }) => {
            await this.$nextTick();
            component.selectRowsByIndexes([0]);
          })
        )
      )
    );
    return {
      focusFirstRow$,
      focusExactMatch$,
      startUp$: gridKeybindings.call(this, gridReady$),
      //Load the data for the selected macro when the selectedRowsData comes in the devExtreme component.
      selectedMacro$: this.selectionChange$.pipe(
        tap(data => {
          const { selectedRowsData } = data.event.msg;
          this.selectedMacro = selectedRowsData[0];
        })
      )
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      specimens: state => state.accessionStore.specimens,
      MacroWindowClinical: state => state.labSettings.MacroWindowClinical,
      patientAge: state => state.accessionStore.caseHeader.patientAgeAtReceiptDisplay,
      bodyPartOptions: state => state.dropdowns.bodyParts,
      historyCount: state => state.accessionStore.caseCounts.historyCount,
      legacyHistoryCount: state => state.accessionStore.caseCounts.legacyHistoryCount,
      slideImages: state => state.accessionStore.slideImages,
      macroWindowGross: state => state.applicationSettings.macroWindowGross,
      caseImages: state => state.accessionStore.caseImages,
      currentUser: state => state.currentUser,
      macroDefaultInput: state => state.applicationSettings.macroDefaultInput,
      primaryMacroWindowText: state => state.applicationSettings.primaryMacroWindowText,
      lastResultsMacro: state => state.accessionStore.lastResultsMacro,
      displayLastMacro: state => state.applicationSettings.displayLastMacro,
      UsesSpecimenTypes: state => state.labSettings.UsesSpecimenTypes
    }),
    currentSpecimenClinical() {
      let targetSpecimen = this.target;
      // IP-174 -- a lab flag will determine if the first specimen's clinical is displayed by default.'
      if (
        this.MacroWindowClinical === MacroWindowClinicalEnum.FirstSpecimen &&
        this.specimens?.length
      ) {
        targetSpecimen = this.specimens[0];
      }

      if (targetSpecimen?.clinical) {
        const textValue = getTextFromHtml(targetSpecimen.clinical);
        if (typeof textValue === "string") {
          return textValue;
        }
        return null;
      }
      return "";
    },
    currentSpecimenGross() {
      if (this.macroWindowGross) {
        const targetSpecimen = this.target;
        if (targetSpecimen?.gross) {
          const textValue = getTextFromHtml(targetSpecimen.gross);
          if (typeof textValue === "string") {
            return textValue;
          }
          return null;
        }
      }
      return "";
    },
    ...mapGetters(["permissions"]),
    ...mapGetters("accessionStore", ["primaryPathologist"]),
    gridName() {
      if (this.targetType === 4) {
        return "generalMacros";
      }
      return "resultsMacros";
    },
    hasEditPermission() {
      if (this.targetType === 4) {
        return this.permissions.MacroGeneralCreateEdit;
      } else {
        return this.permissions.MacroResultsCreateEdit;
      }
    },
    selectedMacroId() {
      return this.selectedMacro?.macroId;
    },
    searchPanel() {
      return {
        searchVisibleColumnsOnly: this.searchType,
        searchOperation: this.startsWith ? "startswith" : "contains",
        visible: true,
        width: "300px"
      };
    },
    dataSource() {
      const targetType = this.targetType === 4 ? this.targetType : 0;
      const permissions = {
        diagnosis: this.permissions.CaseFieldEditDiagnosis,
        gross: this.permissions.CaseFieldEditGross,
        clinical: this.permissions.CaseFieldEditClinical,
        caseNotes: this.permissions.CaseFieldEditCaseNotes,
        microscopic: this.permissions.CaseFieldEditMicroscopic,
        notes: this.permissions.CaseFieldEditSpecimenNotes
      };
      const isAllowedToResult = permissions.diagnosis;
      let userId = this.currentUser?.id;
      const primaryPathologistId = this.pathologistGuid || this.primaryPathologist?.guid;
      if (primaryPathologistId && isAllowedToResult) {
        userId = primaryPathologistId;
      }
      const startsWith = this.startsWith;
      const specimenTypeId =
        this.UsesSpecimenTypes && targetType === MacroTypeEnum.Results ? this.specimenTypeId : null;
      const customStore = new CustomStore({
        load(loadOptions) {
          if (!loadOptions?.filter) {
            return [];
          }
          return MacrosApi.getMacrosByUserAndType({
            userId,
            macroTypeId: targetType,
            loadOptions,
            specimenTypeId
          });
        }
      });

      return new DataSource({
        store: customStore,
        key: "macroId",
        searchOperation: startsWith ? "startswith" : "contains"
      });
    },
    patientDOB() {
      if (this.caseDetails.patientDOB) {
        return moment(this.caseDetails.patientDOB).format("MM/DD/YYYY");
      }
      return "N/A";
    },
    hasSelected() {
      return this.selectedMacro?.macroId;
    },
    icdCodes() {
      return this.selectedMacro.icdCodes.flat();
    },
    holdCodes() {
      return this.selectedMacro.holdCodes.flat();
    },
    fieldMap() {
      switch (this.targetType) {
        case MacroTypeEnum.General:
          return ["generalText"];
        default:
          return ["diagnosis", "microscopic", "specimenNotes", "caseNotes", "gross"];
      }
    },
    target() {
      return this.targetSpecimen;
    },
    targetTitle() {
      if (this.target) {
        return this.buildTitle(this.target);
      }
      return "";
    },
    microscopicHTML() {
      return this.selectedMacro.microscopic;
    },
    notesHTML() {
      return this.selectedMacro.specimenNotes;
    },
    caseNotesHTML() {
      return this.selectedMacro.caseNotes;
    },
    grossHTML() {
      return this.selectedMacro.gross;
    },
    clinicalHTML() {
      return this.selectedMacro.clinical;
    },
    columns() {
      if (this.targetType === 0) {
        return [
          {
            dataField: "macroName",
            caption: "Macro Name",
            sortOrder: "asc",
            visibleIndex: 1,
            sortIndex: 0,
            dataType: "string",
            calculateFilterExpression: (selector, comparisonOperator) => {
              return [
                "macroName",
                comparisonOperator
                  ? comparisonOperator
                  : this.startsWith
                  ? "startswith"
                  : "contains",
                selector
              ];
            }
          },
          {
            dataField: "diagnosis",
            visibleIndex: 2,
            dataType: "string",
            allowSearch: !this.searchType,
            calculateDisplayValue(data) {
              if (data.diagnosis) {
                return getTextFromHtml(data.diagnosis);
              }
              return "";
            },
            calculateFilterExpression: (selector, comparisonOperator) => {
              return [
                "diagnosis",
                comparisonOperator
                  ? comparisonOperator
                  : this.startsWith
                  ? "startswith"
                  : "contains",
                selector
              ];
            },
            width: 300
          },
          {
            dataField: "microscopic",
            dataType: "string",
            visible: false,
            allowSearch: !this.searchType,
            calculateDisplayValue(data) {
              if (data.microscopic) {
                return getTextFromHtml(data.microscopic);
              }
              return "";
            },
            calculateFilterExpression: (selector, comparisonOperator) => {
              return [
                "microscopic",
                comparisonOperator
                  ? comparisonOperator
                  : this.startsWith
                  ? "startswith"
                  : "contains",
                selector
              ];
            }
          },
          {
            dataField: "specimenNotes",
            dataType: "string",
            visible: false,
            allowSearch: !this.searchType,
            calculateDisplayValue(data) {
              return getTextFromHtml(data.specimenNotes);
            },
            calculateFilterExpression: (selector, comparisonOperator) => {
              return [
                "specimenNotes",
                comparisonOperator
                  ? comparisonOperator
                  : this.startsWith
                  ? "startswith"
                  : "contains",
                selector
              ];
            }
          },
          {
            dataField: "caseNotes",
            dataType: "string",
            visible: false,
            allowSearch: !this.searchType,
            calculateDisplayValue(data) {
              if (data.caseNotes) {
                return getTextFromHtml(data.caseNotes);
              } else {
                return "";
              }
            },
            calculateFilterExpression: (selector, comparisonOperator) => {
              return [
                "caseNotes",
                comparisonOperator
                  ? comparisonOperator
                  : this.startsWith
                  ? "startswith"
                  : "contains",
                selector
              ];
            }
          },
          { dataType: "string", dataField: "description", allowSearch: !this.searchType },
          {
            caption: "Actions",
            visibleIndex: 0,
            type: "buttons",
            cellTemplate: "actions"
          }
        ];
      }

      return [
        {
          dataField: "macroName",
          caption: "Macro Name",
          sortOrder: "asc",
          visibleIndex: 1,
          sortIndex: 0,
          dataType: "string",
          calculateFilterExpression: (selector, comparisonOperator) => {
            return [
              "macroName",
              comparisonOperator ? comparisonOperator : this.startsWith ? "startswith" : "contains",
              selector
            ];
          }
        },
        {
          dataField: "generalText",
          visibleIndex: 2,
          dataType: "string",
          allowSearch: !this.searchType,
          calculateDisplayValue(data) {
            if (data.generalText) {
              return getTextFromHtml(data.generalText);
            }
            return "";
          },
          calculateFilterExpression: (selector, comparisonOperator) => {
            return [
              "generalText",
              comparisonOperator ? comparisonOperator : this.startsWith ? "startswith" : "contains",
              selector
            ];
          }
        },

        {
          caption: "Actions",
          visibleIndex: 0,
          type: "buttons",
          cellTemplate: "actions"
        }
      ];
    },
    macroType() {
      // Set the macro type to results if its not general.
      //! Check this in order to support panel macros.
      const targetType = this.targetType !== 4 ? 0 : this.targetType;
      if (targetType !== undefined || targetType !== null) {
        const macroType = this.macroTypes.find(e => e.id === targetType);
        if (macroType) {
          return macroType.displayName;
        }
      }
      return "All Macros";
    },
    macroFields() {
      let macroMap = this.typeMap[this.macroType];
      if (macroMap) {
        macroMap = macroMap.filter(e => {
          return e != "users";
        });
        return macroMap;
      }
      return [];
    },
    typeDisplay() {
      const targetType = this.targetType > 4 ? 0 : this.targetType;
      const type = this.macroTypes?.find(e => e.id === targetType);
      return type?.displayName ? type.displayName : null;
    },
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    allFieldsSelected() {
      return Object.values(this.targetFields).every(field => field);
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    map() {
      return {
        Results: [
          "diagnosisSummaryId",
          "diagnosis",
          "interfaceDiagnosis",
          "microscopic",
          "specimenNotes",
          "caseNotes",
          "cptCodes",
          "holdCodes",
          "icdCodes"
        ],
        General: [
          "generalText",
          "cptCodes",
          "holdCodes",
          "icdCodes",
          "procedures",
          "images",
          "tags"
        ]
      };
    },
    shortenedCaseNumber() {
      return shortenAccessionNumber(this.caseDetails.caseNumber);
    },
    primaryProviderAndLocation() {
      const primaryProvider = this.caseDetails.contacts.find(e => (e.isPrimary = true));
      if (!primaryProvider) {
        return "Not assigned";
      }
      return primaryProvider.contact.displayName;
    },
    primaryPathologistName() {
      if (!this.targetSpecimen) {
        return "";
      }
      const primaryPathologist = this.targetSpecimen?.pathologists.find(e => (e.isPrimary = true));
      if (!primaryPathologist) {
        return "";
      }
      if (primaryPathologist?.displayName) {
        return primaryPathologist.displayName.replace(/ \(.+\)/, "");
      }
      return `${primaryPathologist.firstName} ${primaryPathologist.lastName}`;
    },
    showSlidesButton() {
      if (this.targetType !== MacroTypeEnum.Results || this.$route?.name !== "SpecimenResults") {
        return false;
      }
      if (this.slideImages?.length > 0) {
        const slides = this.slideImages.find(e => e.specimenId === this.target.id);
        return slides?.slideImageUrls?.length > 0 || slides?.specimenSlideImageUrl;
      }
      return false;
    },
    hasImagesOnReport() {
      if (this.caseImages.length) {
        for (const image of this.caseImages) {
          if (image?.printOnReport) {
            return true;
          }
        }
      }
      return false;
    },
    shortkeys() {
      if (this.isManagementOpen || this.isHistoryOpen) {
        return null;
      }
      return {
        enter: ["enter"],
        e: altKey("e"),
        h: altKey("h"),
        i: altKey("i"),
        s: altKey("s"),
        tab: ["tab"]
      };
    }
  },
  watch: {
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    }
  },
  methods: {
    handlePreloadFields(targetType) {
      switch (targetType) {
        case MacroTypeEnum.Results:
          {
            this.targetFields = {
              caseNotes: true,
              clinical: true,
              diagnosis: true,
              microscopic: true,
              specimenNotes: true,
              gross: true
            };
          }
          break;
        case MacroTypeEnum.Panel:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: true,
              gross: false
            };
          }
          break;
        case MacroTypeEnum.Protocol:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: false,
              gross: true
            };
          }
          break;
        case MacroTypeEnum.General:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: false,
              gross: false,
              generalText: true
            };
          }
          break;
        case MacroTypeEnum.Diagnosis:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: true,
              microscopic: false,
              specimenNotes: false,
              gross: false,
              generalText: false
            };
          }
          break;
        case MacroTypeEnum.Gross:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: false,
              gross: true,
              generalText: false
            };
          }
          break;
        case MacroTypeEnum.Microscopic:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: true,
              specimenNotes: false,
              gross: false,
              generalText: false
            };
          }
          break;
        case MacroTypeEnum.SpecimenNote:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: true,
              gross: false,
              generalText: false
            };
          }
          break;
        case MacroTypeEnum.CaseNote:
          {
            this.targetFields = {
              caseNotes: true,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: false,
              gross: false,
              generalText: false
            };
          }
          break;
        default: {
          this.targetFields = {
            caseNotes: true,
            clinical: true,
            diagnosis: true,
            microscopic: true,
            specimenNotes: true,
            gross: true
          };
        }
      }
    },
    initGrid({ component }) {
      this.grid = component;
    },
    editMacro({ data }) {
      this.selectedMacro = data;
      this.isManagementOpen = true;
    },
    handleCancelConfig() {
      this.isManagementOpen = false;
      this.isCloning = false;
    },
    handleConfigSubmit(data) {
      this.selectedMacro = data;
      if (this.grid) {
        this.grid.refresh(true);
        this.macroIdToSelect = data.macroId;
      }
      this.isManagementOpen = false;
      this.isCloning = false;
    },
    cloneMacro({ data }) {
      this.selectedMacro = data;
      this.isManagementOpen = true;
      this.isCloning = true;
    },
    startCase,
    buildTitle(specimen = this.target) {
      let specimenOrder = specimen.specimenOrder || "";
      let protocol = this.mappedProtocol[specimen.protocolId] || "";
      let bodyPart = this.mappedBodyPart[specimen.bodyPartId] || "";
      let site = specimen.site || "";
      const specimenIndex = this.specimens.map(e => e.specimenOrder).indexOf(specimenOrder) + 1;
      return `<div class="d-flex align-items-baseline text-capitalize">
     <span class="m-0">
      Specimen: <span class="border-right pr-2"><span style="color:red">${specimenOrder}</span> <span class="text-lowercase" style="font-size:1rem;word-spacing:-0.05em;">(${specimenIndex} of ${
        this.specimens.length
      })</span></span>   ${site}${bodyPart ? ", " + bodyPart : ""}${protocol ? ", " + protocol : ""}
     </span>
      </div>`;
    },
    async handleSubmit(data) {
      const selectedMacro = data?.macroId ? data : this.selectedMacro;
      if (!selectedMacro?.macroId) {
        window.notify("Please select a macro to apply.", "warning");
        return;
      }
      const targetMacro = await MacrosApi.getMacroDetails(selectedMacro.macroId);
      if (Object.keys(this.procedurePopupTextToReplace).length) {
        this.$emit("macroSelected", targetMacro);
        return;
      }

      Object.keys(this.targetFields).forEach(field => {
        if (!this.targetFields[field]) {
          targetMacro[field] = null;
        }
      });
      // If macro is for a specific field besides diagnosis, ignore other secondary macro items
      if (this.targetType > MacroTypeEnum.Diagnosis) {
        for (const field of ["cptCodes", "holdCodes", "icdCodes", "procedures"]) {
          targetMacro[field] = [];
        }
        targetMacro.interfaceDiagnosis = "";
        targetMacro.textOnly = true;
      }
      if (targetMacro.macroType === MacroTypeEnum.Results) {
        this.$store.commit("accessionStore/setLastResultsMacro", targetMacro.macroName);
      }
      if (this.dialogFromWysiwyg) {
        eventBus.$emit(RESTORE_EDITOR_POSITION, [targetMacro]);
      } else {
        this.$emit("macroSelected", [targetMacro]);
      }
    },
    handleSelectAllFields() {
      if (this.allFieldsSelected) {
        this.targetFields = this.fieldMap.reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {});
      } else {
        this.targetFields = this.fieldMap.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
    },
    toggleMacro() {
      this.$emit("close");
    },
    displayDate(date) {
      if (date instanceof Date) {
        return format(date, "MM/dd/yyy");
      } else {
        date = new Date(date);
      }
      if (!Number.isNaN(Number(date))) {
        return format(date, "MM/dd/yyyy");
      }
      return "N/A";
    },
    handleSelect(macro) {
      this.macro.callback(macro);
      this.$store.commit("clearMacro");
    },
    displayField(field) {
      if (field === "diagnosisSummaryId") {
        return "Diagnosis Summary";
      }
      return field.replace(/([a-z])([A-Z])/g, "$1 $2");
    },
    // displayProcedure(id) {
    //   const procedure = this.procedureOptions.find(e => e.id === id);
    //   if (procedure) {
    //     return procedure.displayName;
    //   }
    //   return "N/A";
    // },
    displayDiagnosisSummary(diagnosisSummaryId) {
      if (diagnosisSummaryId) {
        const diagnosis = this.diagnosisSummaries.find(e => e.id === diagnosisSummaryId);
        if (diagnosis) {
          const classes = {
            "bg-danger": diagnosis.backgroundColor === 2,
            "bg-success": diagnosis.backgroundColor === 1,
            "bg-warning": diagnosis.backgroundColor === 3
          };
          return { displayText: diagnosis.displayText, classes };
        }
      }
      return { displayText: "N/A", classes: {} };
    },
    arrayProperty(property) {
      return Array.isArray(property);
    },
    lastNumberInRow(property) {
      const { patientMRN, acctNumber, orderNumber } = this.caseDetails;
      const lastNumber = () => {
        if (orderNumber) {
          return "orderNumber";
        }
        if (acctNumber) {
          return "acctNumber";
        }
        if (patientMRN) {
          return "patientMRN";
        }
      };
      if (lastNumber() !== property) {
        return "border-right mx-1 pr-2";
      }
      return "ml-1";
    },
    onEditorPreparing(e) {
      if (this.startsWith) {
        if (e.parentType === "searchPanel") {
          e.setValue = function (value) {
            if (/ $/i.test(value)) {
              e.component.searchByText(value.replaceAll(" ", ""));
              if (this.lastSearch) {
                this.lastSearch = this.lastSearch.replaceAll(" ", "");
              }
            } else {
              e.component.searchByText(value);
            }
          };
        }
      }
    },
    launchSlideImages(specimenId) {
      eventBus.$emit(OPEN_SLIDE_IMAGES, specimenId);
    },
    onFocusedCellChanged(data) {
      this.grid.selectRowsByIndexes(data.rowIndex);
      this.$nextTick(() => {
        const searchInput = document.getElementById("searchPanel");
        searchInput.focus();
      });
    },
    handleShortkey({ srcKey }) {
      const el = document.activeElement;
      switch (srcKey) {
        case "e":
        case "tab":
          this.exactMatchText = "";
          this.grid.searchByText("");
          this.grid.clearSelection();
          if (el?.id !== "exact-match") {
            this.$refs.exactMatch.focus();
          } else {
            this.focusSearchPanel();
          }
          break;
        case "s":
        case "enter":
          if (el?.id === "exact-match") {
            this.handleExactMatch();
          } else {
            this.handleSubmit();
          }
          break;
        case "h":
          this.toggleHistory();
          break;
        case "i":
          if (this.caseImages?.length) {
            this.toggleImagesPopup();
          }
          break;
      }
    },
    async handleExactMatch() {
      this.exactMatchText = this.exactMatchText.trim();
      if (!this.exactMatchText) {
        window.notify("Please enter text to find exact macro.", "warning");
        return;
      }
      if (this.exactMatchText?.length) {
        const macroName = this.exactMatchText;
        const userId = this.primaryPathologist?.guid || this.currentUser?.id;
        const macroData = await MacrosApi.getMacrosByUser(userId).load({
          filter: [
            [
              "macroType",
              this.targetType === MacroTypeEnum.General
                ? MacroTypeEnum.General
                : MacroTypeEnum.Results
            ],
            "and",
            ["macroName", "=", macroName],
            "and",
            dateRangeFilter("effectiveOn", "expiresOn")
          ]
        });
        if (macroData?.length === 1) {
          this.handleSubmit(macroData[0]);
        } else {
          window.notify("No matching macro found.", "warning");
        }
      }
    },
    focusExactMatch() {
      if (this.macroDefaultInput) {
        this.$nextTick(() => {
          this.$refs.exactMatch.focus();
          if (this.displayLastMacro && this.lastResultsMacro) {
            this.exactMatchText = this.lastResultsMacro;
            this.$refs.exactMatch.selectAll();
          }
        });
      } else if (this.displayLastMacro && this.lastResultsMacro) {
        setTimeout(() => {
          this.grid.searchByText(this.lastResultsMacro), 500;
          const searchPanel = document.getElementById("searchPanel");
          if (searchPanel && searchPanel.value) {
            searchPanel.selectionStart = 0;
          }
        });
      }
    },
    focusSearchPanel() {
      const searchPanel = document.getElementById("searchPanel");
      searchPanel.focus();
    },
    toggleHistory() {
      this.isHistoryOpen = true;
    },
    toggleImagesPopup() {
      this.isImagesOpen = !this.isImagesOpen;
    },
    getTextClass(field) {
      if (this.primaryMacroWindowText === MacroWindowPrimaryTextEnum[field]) {
        return "prominent-clinical-gross-text";
      }
      return "clinical-gross-text";
    },
    checkSpecimenType() {
      if (this.UsesSpecimenTypes && this.target?.protocolId) {
        MacrosApi.getMacroDetails(this.target.protocolId).then(res => {
          this.specimenTypeId = res?.specimenTypeId;
        });
      }
    },
    handleCloseSlideImages() {
      this.macroDefaultInput ? this.focusExactMatch() : this.focusSearchPanel();
    }
  },
  directives: {
    focusItem: {
      inserted(el) {
        return el.focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.macro_popup {
  .grid {
    height: 40vh;
  }
  .summary {
    height: 25vh;
  }
}
.value {
  margin: 5px 2px;
}
.summary {
  overflow: auto;
}
.target_title {
  color: $primary-dark;
}
.clinical-gross-text {
  font-size: 1.2rem;
  color: $primary-dark;
  width: 650px;
  max-width: 100%;
  margin-bottom: 0;
}
.prominent-clinical-gross-text {
  font-size: 1.5rem;
  color: $primary-darker;
  width: 650px;
  max-width: 100%;
  margin-bottom: 0;
}
.large-case-number {
  font-size: 28px;
}
.pathologist-name {
  font-size: 1rem;
  color: $primary-dark;
  width: 650px;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.exact-match {
  width: 300px;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 0;
  min-height: 10px;
}
.large-header-text {
  font-size: 1.1rem;
}
</style>
