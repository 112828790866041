<template>
  <div :class="$attrs.class + ' contact-wrapper'" v-if="!noPrimary">
    <label class="provider-label" v-if="$attrs.label"
      ><span v-html="labelName"></span
      ><icon-button
        icon="search-location"
        class="text-primary align-self-start provider-search"
        @click="isSearchingContact = !isSearchingContact"
    /></label>

    <MultiSelectWithSearch
      ref="selector"
      data-testid="contactSelector"
      :multiple="true"
      trackBy="id"
      placeholder="Select Providers"
      :dataSource="dataSource"
      v-bind="$attrs"
      label=""
      v-model="providers"
      v-on="$listeners"
      :columns="columns"
      :select="select"
      :sort="sort"
      :fixedFilter="rangeFilter"
      filterType="startsWith"
      :tabIndex="tabProp"
      @search-change="searchChange"
    >
      <template v-slot:option="{ option }">
        <span v-if="!isSecondaryOnly(option)" class="option__title" :class="getClass(option)">{{
          displayName(option)
        }}</span>
        <span v-else class="option__title secondary_option">
          <span class="secondary-icon mr-2">S</span>
          {{ displayName(option) }}
        </span>
      </template>
      <template v-slot:tag="{ option }">
        <span class="custom__tag" :class="{ isSecondary: isSecondaryOnly(option) }">
          <span>{{ displayName(option) }}</span>
          <div class="custom__remove d-flex">
            <icon-button
              v-tooltip="'Remove option'"
              @click.prevent="removeOption(option)"
              type="button"
              class="btn text-danger"
              icon="trash-alt"
              :tabindex="tabProp"
            />
            <button
              v-tooltip.top="
                option.isPrimary
                  ? 'Primary'
                  : !isSecondaryOnly(option)
                  ? 'Alternate'
                  : 'Secondary Only'
              "
              type="button"
              class="btn"
              @click="() => !isSecondaryOnly(option) && setPrimary(option)"
              :tabindex="tabProp"
            >
              <template v-if="option.isPrimary && !isSecondaryOnly(option)">
                <icon class="text-primary" icon="parking" />
              </template>
              <template v-else>
                <span class="secondary-icon font-weight-bold">S</span>
              </template>
            </button>
          </div>
        </span>
      </template>
    </MultiSelectWithSearch>
    <modal :status="isSearchingContact" @close="isSearchingContact = !isSearchingContact">
      <select-contact-popup :selectedContacts="value" @submit="handleContactSelected" />
    </modal>
  </div>
  <tag-input
    v-else
    :dataSource="tagsSource"
    v-bind="$attrs"
    valueExpr="contactId"
    :maxDisplayedTags="1"
    :displayExpr="displayName"
    v-model="providers"
    v-on="$listeners"
    searchMode="startswith"
    searchExpr="displayName"
    :grayOutExpired="true"
    selectAllMode="page"
    :enableSelectPopup="true"
    :selectedItems="selectedItems"
    @tagClick="handleTagClick"
    @closePopup="handleClosePopup"
  />
</template>

<script>
import MultiSelectWithSearch from "@/components/common/MultiSelectWithSearch.vue";
import ProviderAPI from "@/services/providers";
import { mapState } from "vuex";
import { dateRangeFilter, fixProviderPayload } from "@/modules/helpers";
import Icon from "@/components/common/Icon.vue";
import IconButton from "@/components/common/IconButton.vue";
import TagInput from "@/components/common/TagInput.vue";
import DataSource from "devextreme/data/data_source";
import Modal from "@/components/common/Modal.vue";
import { uniqBy } from "lodash";
import SelectContactPopup from "@/components/SelectContactPopup.vue";
import { DropdownApi, ProvidersApi } from "@/services";
import { sortBy } from "lodash";
export default {
  name: "Contact",
  inheritAttrs: false,
  components: {
    MultiSelectWithSearch,
    Icon,
    IconButton,
    TagInput,
    Modal,
    SelectContactPopup
  },
  props: {
    noPrimary: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    },
    tabProp: {
      default: 0
    },
    accessKey: {
      type: String
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    },
    isSearchingContact: false,
    columns: ["displayName"],
    select: [
      "provider.firstName",
      "provider.lastName",
      "provider.isSecondaryOnly",
      "properties.defaultBillingType",
      "properties.specimenNumbering",
      "location.name",
      "contactId",
      "displayName"
    ],
    sort: "displayName",
    rangeFilter: dateRangeFilter("properties.effectiveOn", "properties.expiryOn"),
    searchQuery: "",
    selectedContacts: [],
    selectedItems: []
  }),
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    providers: {
      get() {
        return this.value;
      },
      set(value) {
        value = fixProviderPayload(value);
        this.$emit("input", value);
        return value;
      }
    },

    dataSource() {
      return new DataSource({
        store: this.searchQuery.length ? DropdownApi.searchContacts : [],
        filter: ["displayName", "startswith", this.searchQuery],
        sort: [
          { selector: "isPrimaryProviderLocation", desc: true },
          { selector: "displayName", desc: false }
        ]
      });
    },
    tagsSource() {
      return new DataSource({
        store: ProviderAPI.searchContacts,
        filter: dateRangeFilter("properties.effectiveOn", "properties.expiryOn"),
        sort: "displayName"
      });
    },
    isFocused() {
      return this.$refs.selector.isFocused;
    },
    labelName() {
      const key = this.accessKey || "";
      const name = this.$attrs.label;
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name || ""}</b>`;
    }
  },
  watch: {
    providers: {
      immediate: true,
      handler(nv, ov) {
        if (this.noPrimary) {
          if (!nv) {
            this.selectedContacts = [];
          }
          this.$nextTick(() => {
            ProvidersApi.getContactsById(nv).then(res => {
              this.selectedContacts = res;
            });
          });
          return;
        }
        if (nv?.length && ov !== undefined) {
          const onePrimary = nv.find(
            contact =>
              (!contact?.isSecondaryOnly || !contact?.provider?.secondaryOnly) && contact.isPrimary
          );
          if (!onePrimary?.contactId) {
            const firstAvailablePrimary = nv.find(contact => {
              return !contact?.isSecondaryOnly && !contact?.provider?.secondaryOnly;
            });
            if (firstAvailablePrimary) {
              this.setPrimary(firstAvailablePrimary);
            }
          }
          for (const provider of this.providers) {
            if (!provider.isPrimary) {
              provider.isPrimary = false;
            }
          }
        }
      }
    }
  },
  methods: {
    handleContactSelected(data) {
      this.isSearchingContact = false;
      this.providers = uniqBy([...this.providers, ...data], "contactId");
    },
    displayName(option) {
      let returnValue = `Provider id ${option.contactId}`;
      if (option.displayName) {
        returnValue = option.displayName;
      } else if (option.provider?.firstName && option.provider?.lastName) {
        returnValue = `${option.provider.lastName}, ${option.provider.firstName} (${option.location.name})`;
      } else if (option?.contact?.displayName) {
        returnValue = option.contact.displayName;
      }
      if (option?.isPrimaryProviderLocation) {
        returnValue += " [P]";
      }
      return returnValue;
    },
    focus() {
      this.$refs.selector.focus();
    },

    setPrimary(option) {
      const optionId = option?.id || option?.contactId;
      this.providers = this.providers.map(e => {
        const contactId = e?.id || e?.contactId;
        if (
          optionId === contactId &&
          (!e.isSecondaryOnly ||
            !e.provider?.isSecondaryOnly ||
            !e.contact?.provider?.isSecondaryOnly)
        ) {
          e.isPrimary = true;
        } else {
          e.isPrimary = false;
        }
        return e;
      });
    },
    isSecondaryOnly(option) {
      if (option.provider?.isSecondaryOnly) {
        return option.provider.isSecondaryOnly;
      } else if (option.contact?.provider?.isSecondaryOnly) {
        return option.contact.provider.isSecondaryOnly;
      } else if (option?.isSecondaryOnly) {
        return option.isSecondaryOnly;
      }

      {
        return false;
      }
    },
    onValueChanged(e) {
      this.providers = e.value;
    },
    searchChange(query) {
      this.searchQuery = query;
    },
    removeOption(option) {
      const contactId = option?.id || option?.contactId;
      this.$emit(
        "input",
        this.value.filter(e => e?.id !== contactId && e?.contactId !== contactId)
      );
    },
    handleTagClick() {
      this.selectedItems = sortBy(this.selectedContacts, [
        "provider.lastName",
        "provider.firstName"
      ]);
    },
    handleClosePopup() {
      this.selectedItems = [];
    },
    getClass(provider) {
      if (provider?.isPrimaryProviderLocation) {
        return "primary-location";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.isSecondary {
  background-color: #c8c8c8;
}
.secondary-icon {
  width: 1rem !important;
  height: 1rem !important;
  font-size: 1rem;
  font-weight: bolder;
  padding: 0.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $gray;
}

.provider-label {
  position: relative;
  .provider-search {
    position: absolute;
    top: -0.52rem;
  }
}

.option_title {
  .secondary-icon:hover {
    color: $gray;
  }
}
.primary-location {
  font-weight: 900;
}
</style>
