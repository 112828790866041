<template>
  <div
    class="header_wrapper case-header rounded p-0 py-1"
    v-shortkey="shortkeys"
    @shortkey="handleShortKey"
  >
    <div class="row row-wrap py-1">
      <div class="col-12 col-md-3 col-xl-2 justify-content-center d-lg-flex status-header">
        <div class="d-lg-flex mr-2 text-center flex-column">
          <icon-button
            @click="handleCaseDelete"
            v-if="permissions.CaseDelete"
            :disabled="isLoading"
            icon="trash-alt"
            type="button"
            data-testid="deleteCase"
            v-tooltip="'Click to delete this case.'"
            class="btn text-danger my-1 align-self-center"
          />
          <icon-button
            @click="handleChangeNumber"
            v-if="permissions.CaseCreateEdit"
            :disabled="isLoading"
            icon="info-circle"
            type="button"
            data-testid="changePrefix"
            v-tooltip="'Click to change case prefix & number.'"
            class="btn text-primary my-1 align-self-center"
          />
        </div>
        <div class="d-flex flex-column text-center">
          <span class="status-header_caseNumber mb-1">{{ shortenedAccessionNumber }}</span>
          <h3 class="h3 mb-0" v-if="currentSpecimenOrder">
            {{ currentSpecimenOrder }}
          </h3>
          <div class="">
            <span v-if="!specimenNumber && specimenCount" class="header-property_name"
              >Total Specimens:</span
            >
            <span v-else-if="specimenNumber">
              <span class="header-property_name">Specimen </span>
              <span>{{ specimenNumber }}</span> <span class="header-property_name">of</span></span
            >
            <span v-else-if="specimenCount">No Specimens:</span>
            <span v-show="specimenCount" class="ml-2">{{ specimenCount }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 col-lg-7 d-block d-lg-flex">
        <div class="col-12 col-lg-4 dates-block">
          <div class="header-property d-flex">
            <span class="header-property_name mr-2">Collected:</span>
            <span data-testid="collectedOn" class="header-property_date">{{
              dateFormatter(caseHeader.collectedOn)
            }}</span>
          </div>
          <div class="header-property d-flex">
            <span class="header-property_name mr-2">Received:</span>
            <span data-testid="receivedOn" class="header-property_date">{{
              dateFormatter(caseHeader.receivedOn)
            }}</span>
          </div>
          <div class="header-property d-flex">
            <span class="header-property_name mr-2 mr-1">Acc: </span>
            <span data-testid="createdOn" class="header-property_date">{{
              dateTimeFormatter(caseHeader.createdOn)
            }}</span>
          </div>
          <div
            v-if="caseHeader.signedOn"
            class="header-property d-flex"
            v-show="caseHeader.signedOn"
          >
            <span class="header-property_name mr-2">Signed:</span>
            <span data-testid="signedOn" class="header-property_date">{{
              dateTimeFormatter(caseHeader.signedOn)
            }}</span>
          </div>
          <div v-if="caseHeader.reportedOn" class="header-property d-flex">
            <span class="header-property_name mr-2 text-danger">Reported:</span>
            <span data-testid="reportedOn" class="header-property_date">{{
              dateTimeFormatter(caseHeader.reportedOn)
            }}</span>
          </div>
        </div>
        <div class="d-lg-none col-12">
          <hr class="my-2" />
        </div>
        <div class="col">
          <div class="header-property text-lg">
            <span class="text-lg font-weight-bold mb-0" data-private="redact">
              {{ caseHeader.name }}
            </span>
            <span v-show="patientSex" class="mx-2 text-lg"> ({{ patientSex }}) </span>
            <span class="text-lg" v-if="caseHeader.dob">
              <span class="text-lg" data-private="redact">{{ dateFormatter(caseHeader.dob) }}</span>
              <span class="px-1 text-lg"
                >({{ caseHeader.patientAgeAtReceiptDisplay.replace(/ years/gi, "") }})</span
              >
            </span>
            <span
              v-if="showManualAccession"
              class="btn align-self-center p-1 btn-danger"
              v-tooltip="'Case was accessioned manually'"
            >
              Manual
            </span>
          </div>
          <div>
            <span class="header-property_name" v-if="patientRace">Race: </span>
            <span class="text-capitalize">{{ patientRace }}</span>
          </div>
          <div>
            <span class="header-property_name" v-if="casePhoneNumber" data-private="redact"
              >Phone #:
              <a class="telephone" :href="`tel:${this.caseHeader.phone}`">
                {{ casePhoneNumber }}
              </a>
            </span>
          </div>
          <div>
            <span class="header-property_name mr-1" v-if="caseHeader.orderNumber"
              >Order #:
              <span> {{ caseHeader.orderNumber }}</span>
            </span>

            <span class="header-property_name mr-1" v-if="caseHeader.mrn" data-private="redact"
              >MRN:
              <span>
                {{ caseHeader.mrn }}
              </span>
            </span>
            <span
              class="header-property_name mr-1"
              v-if="caseHeader.accountNumber"
              data-private="redact"
              >Acct #: <span>{{ caseHeader.accountNumber }} </span>
            </span>
            <span
              class="header-property_name mr-1"
              v-if="caseHeader.ssn && permissions.SocialSecurityNumberView"
              data-private="redact"
            >
              SSN:
              <span data-private="redact">{{ displaySSN }}</span>
            </span>
          </div>
          <div>
            <span class="header-property_name" v-if="caseHeader.provider">Provider: </span>
            <span
              v-if="caseHeader.provider === 'Not Assigned'"
              class="font-weight-bold text-danger"
              v-tooltip="
                'This case does not have a primary provider assigned. Please navigate to the Demographics tab to assign one.'
              "
            >
              {{ caseHeader.provider }}
            </span>
            <span
              v-else
              :class="
                permissions.ContactCreateEdit && caseHeader.provider !== 'Not Assigned'
                  ? 'contact-link'
                  : ''
              "
              @click="openEditProvider"
              >{{ caseHeader.provider }}
            </span>
          </div>
          <div v-if="providerPhone">
            <span class="header-property_name">Provider Phone #: </span>
            <a :href="'tel:' + providerPhone">{{ providerPhone }}</a>
          </div>
          <div>
            <span class="header-property_name" v-if="caseHeader.location">Location: </span>
            <span>{{ caseHeader.location }}</span>
          </div>
        </div>
      </div>
      <div class="offset-6 col-6 offset-lg-0 col-lg-2 col-xl-3">
        <span v-if="caseHeader.lastEditReason" class="mb-1">
          Last Edit:
          <button
            @click="toggleReason"
            class="btn btn-link text-truncate"
            v-tooltip="'Click for more information.'"
          >
            {{ caseHeader.lastEditReason }}
          </button>
        </span>
        <div class="d-flex flex-wrap">
          <icon-button
            v-if="permissions.PathReportView"
            v-tooltip.right="'View path report.'"
            icon="window-restore"
            class="mr-1 text-primary"
            @click="handleViewPathReport"
            :disabled="isLoading"
          />

          <icon-button
            v-if="permissions.PathReportView && isReported"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to download this accession.'"
            class="mr-1 text-primary"
            @click="downloadCaseReport"
            icon="download"
          />

          <icon-button
            @click="handleDistribution(DistributionMethodsEnum.Print)"
            v-if="permissions.PathReportSendByPrint"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to distribute by print.'"
            class="mr-1 text-primary"
            icon="print"
          />

          <icon-button
            @click="handleDistribution(DistributionMethodsEnum.Fax)"
            v-if="permissions.PathReportSendByFax"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to fax selected accessions.'"
            class="mr-1 text-primary"
            icon="fax"
          />
          <icon-button
            @click="handleDistribution(DistributionMethodsEnum.Email)"
            v-if="permissions.PathReportSendByEmail"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to distribute by email.'"
            class="mr-1 text-primary"
            icon="envelope"
          />

          <icon-button
            @click="handleDistribution(DistributionMethodsEnum.FileDrop)"
            v-if="permissions.PathReportSendByFileDrop"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to distrbute by file drop.'"
            class="mr-1 text-primary"
            icon="file-pdf"
          />
          <icon-button
            @click="handleDistribution(DistributionMethodsEnum.HL7)"
            v-if="permissions.PathReportSendByHL7"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to distribute via hl7 selected accessions.'"
            class="mr-1 text-primary"
            icon="h-square"
          />
          <icon-button
            @click="toggleReReport"
            v-if="
              (permissions.CaseEditAfterReportedDiagnostic ||
                permissions.CaseEditAfterReportedNonDiagnostic) &&
              ([1, 2, 3, 4, 5, 6].includes(editType) || [11, 14].includes(caseHeader.status))
            "
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to Re-Report this accession.'"
            icon="pen-alt"
            class="mr-1 text-primary"
          />
          <icon-button
            @click="showTaskModal = !showTaskModal"
            v-if="permissions.TaskCreate"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to create a task.<br>(Alt + T)'"
            class="mr-1 text-primary"
            :class="{ 'text-danger': caseTasks.length }"
            icon="hammer"
          />
          <icon-button
            @click="showCameraModal = !showCameraModal"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to add an image.<br>(Alt + F9)'"
            class="mr-1 text-primary"
            icon="camera-retro"
          />
          <icon-button
            v-if="orderLookupNumber && isDemographics"
            @click="isOrderPopupOpen = true"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to open patient order matching'"
            class="mr-1 text-primary"
            icon="clipboard-check"
          />
          <icon-button
            v-if="showScanButton"
            @click="openTwain"
            :disabled="isLoading"
            type="button"
            v-tooltip="'Click to open your twain device.'"
            class="mr-1 text-primary"
            icon="eject"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col ml-auto">
        <div class="d-flex justify-content-between">
          <div class="ml-4">
            <div v-if="specimenNumber">
              <div v-if="siteDisplay"><b>Site:</b> {{ siteDisplay }}</div>
              <div v-if="clinicalDisplay"><b>Clinical: </b>{{ clinicalDisplay }}</div>
            </div>
            <div v-else />
          </div>
          <div class="d-flex align-items-start justify-content-end align-items-center">
            <loader size="small" v-show="isLoading" />
            <div v-if="workRateHtml" class="mx-2" v-html="workRateHtml" />
            <button v-if="showSaveButton" class="btn btn-primary mr-1" @click="handleSaveClick">
              <u>S</u>ave
            </button>
            <button
              class="btn btn-primary mr-1"
              v-if="currentRouteName === 'SpecimenResults' && hasProstate"
              v-tooltip="'Click to view prostate diagram.<br>(Alt + B)'"
              @click="toggleProstatePopup"
            >
              Diagram
            </button>
            <button
              v-if="hasSlideImages(currentSpecimen.id)"
              class="btn btn-success mr-1"
              @click="launchSpecimenImages(currentSpecimen.id)"
            >
              Slides
            </button>
            <button v-show="editType" class="btn mr-1 rounded status" @click="toggleReReport">
              {{ `Edit type: ${editTypeName}` }}
            </button>
            <span
              v-tooltip="'Click to view case history.<br>Alt + H'"
              v-if="historyCount + legacyHistoryCount > 0 && currentRouteName !== 'CaseHistory'"
              class="btn btn-danger mr-1"
              @click="toggleHistory"
            >
              History
            </span>
            <span data-testid="caseStatus" v-if="caseHeader.status" class="btn status mr-1">
              {{ caseStatus }}
            </span>
            <span v-if="priority.name" :class="['btn text-white mr-1 ', priority.class]">
              {{ priority.name }}
            </span>
            <button
              v-show="canSignOut"
              class="btn btn-primary text-white"
              @click="handleTriggerSignOut(caseId)"
              :disabled="isLoading"
              data-testid="signout"
            >
              {{ signButton.message }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal :status="isChangingNumber" @close="isChangingNumber = false">
      <change-case-prefix-popup @close="isChangingNumber = false" />
    </modal>
    <modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        @close="isDistributionOpen = false"
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
      />
    </modal>
    <modal :status="reReportPopup" @close="toggleReReport">
      <re-report-popup class="overflow-hidden" />
    </modal>
    <modal :status="showTaskModal" @close="showTaskModal = !showTaskModal">
      <div class="p-3 task-container">
        <page-title :title="shortenedAccessionNumber" />
        <task-management :caseId="caseDetails.caseId" />
      </div>
    </modal>
    <modal :status="showCameraModal" @close="showCameraModal = false">
      <div class="p-3">
        <page-title :title="shortenedAccessionNumber" />
        <case-image-form
          :caseId="caseDetails.caseId"
          :specimenId="currentSpecimen.id"
          @submit="showCameraModal = !showCameraModal"
        />
      </div>
    </modal>
    <modal :status="isHistoryOpen" @close="isHistoryOpen = false">
      <CaseHistory @close="isHistoryOpen = false" />
    </modal>
    <modal :status="isEditReasonOpen" @close="isEditReasonOpen = false"><EditReasonPopup /> </modal>
    <modal :status="isOrderPopupOpen" @close="isOrderPopupOpen = false">
      <HL7OrderPopup
        @close="isOrderPopupOpen = false"
        class="overflow-hidden"
        :searchQuery="orderLookupNumber"
        :inCase="true"
      />
    </modal>
    <modal :status="isNextCaseOpen" @close="closeNextCase" :showMove="true">
      <next-case-popup
        @close="closeNextCase"
        :previousCaseId="caseDetails.caseId"
        :previousCaseNumber="caseDetails.caseNumber"
      />
    </modal>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
      />
    </modal>
    <modal :status="isCollectedPopupOpen" @close="isCollectedPopupOpen = false">
      <collected-popup @submit="handleSetCollected" @cancel="isCollectedPopupOpen = false" />
    </modal>
    <modal :status="isContactFormOpen" @close="toggleContactForm">
      <ContactsForm
        :value="[primaryContactData]"
        @cancel="toggleContactForm"
        @submit="toggleContactForm"
      />
    </modal>
    <modal :status="isScanning" @close="isScanning = false">
      <CaseImageFormTWAIN @close="isScanning = false" />
    </modal>
    <modal :status="isMatching" @close="declineHistoryMatch">
      <PatientMatchingPopup
        :matchedPatients="patientMatchOptions"
        @setPatient="handleMatchPatient"
      />
    </modal>
    <modal :status="isProstatePopupOpen" @close="toggleProstatePopup">
      <ProstateResultsForm @close="toggleProstatePopup" @submit="handleSubmitProstate" />
    </modal>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { format, isValid } from "date-fns";
import dialog from "@/modules/dialog";
import {
  AuditLogApi,
  CasesApi,
  PathReportMessagesApi,
  ReportsApi,
  TaskApi,
  MacrosApi
} from "../../services";
import {
  AuditLogItems,
  AutoNextCaseEnum,
  CaseCreationMethodEnum,
  CaseStatusEnum,
  DistributionMethodsEnum,
  SpecimenFieldRequiredEnum
} from "@/modules/enums";
import {
  altKey,
  createLogItem,
  formatDatetimeCell,
  shortenAccessionNumber,
  checkIfProxy,
  formatSSN,
  getAltKeys,
  isModalOpen,
  getTextFromHtml,
  createPatientMatchPayload,
  bitEnumToArray
  // batchRedirect
} from "../../modules/helpers";
import Modal from "../common/Modal.vue";
import DistributionPopup from "../DistributionPopup.vue";
import Loader from "../common/Loader.vue";
import ReReportPopup from "../ReReportPopup.vue";
import PageTitle from "../common/PageTitle.vue";
import TaskManagement from "../TaskManagement.vue";
import IconButton from "../common/IconButton.vue";
import CaseImageForm from "../CaseImageForm.vue";
import physicianPopup from "@/mixins/physicianPopup";
import CaseImageFormTWAIN from "../CaseImageFormTWAIN.vue";
import eventBus, {
  ENTER_ORDER_DEMO,
  LAST_SPECIMEN_SAVED,
  OPEN_SLIDE_IMAGES,
  PRESSED_SIGNOUT_KEY,
  SCAN_IN_CASE,
  SIGNED_CASE_FROM_PDF,
  TWAIN_TRANSFER,
  SIGN_CASE_FROM_SHORTKEY,
  SCANNED_IMAGE,
  UPDATE_CASE_DEMOGRAPHICS,
  SAVE_FROM_CASE_HEADER,
  OPEN_UNGROSSED_SPECIMEN
} from "@/modules/eventBus";
import EditReasonPopup from "../EditReasonPopup.vue";
import ChangeCasePrefixPopup from "../ChangeCasePrefixPopup.vue";
import CaseHistory from "../CaseHistory.vue";
import { handleErrors } from "@/modules/handleErrors";
import ScannerDetection from "@/modules/scanner";
import HL7OrderPopup from "../HL7OrderPopup.vue";
import NextCasePopup from "@/components/NextCasePopup.vue";
import LabLocationPopup from "../LabLocationPopup.vue";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import getOS from "@/modules/getOs";
import CollectedPopup from "../CollectedPopup.vue";
import ContactsForm from "../forms/CodeMaintenance/Providers/ContactsForm.vue";
import TWAINDriver from "@/modules/TWAINDriver";
import PatientMatchingPopup from "../PatientMatchingPopup.vue";
import { DropdownApi } from "@/services";
import ProstateResultsForm from "../ProstateResultsForm.vue";

export default {
  components: {
    Modal,
    DistributionPopup,
    Loader,
    ReReportPopup,
    PageTitle,
    TaskManagement,
    IconButton,
    CaseImageForm,
    CaseImageFormTWAIN,
    EditReasonPopup,
    ChangeCasePrefixPopup,
    CaseHistory,
    HL7OrderPopup,
    NextCasePopup,
    LabLocationPopup,
    CollectedPopup,
    ContactsForm,
    PatientMatchingPopup,
    ProstateResultsForm
  },
  name: "CaseHeader",
  metaInfo() {
    return {
      title: this.shortenedAccessionNumber,
      titleTemplate: "Case - %s"
    };
  },
  mixins: [physicianPopup],
  data() {
    return {
      isDistributionOpen: false,
      distributionMethod: "",
      isLoading: false,
      isScanning: false,
      isEditReasonOpen: false,
      isHistoryOpen: false,
      DistributionMethodsEnum,
      showTaskModal: false,
      showCameraModal: false,
      caseTasks: [],
      isChangingNumber: false,
      reReportForm: {
        type: null,
        reason: ""
      },
      lastEditReason: "",
      scanner: {},
      isOrderPopupOpen: false,
      inputOrderNumber: null,
      isNextCaseOpen: false,
      isLocationPopupOpen: false,
      shortkeys: {
        ...getAltKeys("bhptx"),
        nextCaseShortkey: getOS().includes("Mac") ? ["ctrl", "k"] : ["alt", "f6"],
        f12: altKey("f12")
      },
      isCollectedPopupOpen: false,
      isProxy: false,
      isContactFormOpen: false,
      patientMatchOptions: [],
      isMatching: false,
      protocolOptions: [],
      isProstatePopupOpen: false,
      workRate: {
        casesResulted: 0,
        specimensResulted: 0,
        casesSigned: 0,
        specimensSigned: 0
      },
      isAutoCheckHistory: false,
      staticCaseId: null
    };
  },

  mounted() {
    this.scanner = new ScannerDetection({
      onComplete: this.scanCase,
      stopPropogation: true,
      minLength: 4
    });

    eventBus.$on(LAST_SPECIMEN_SAVED, () => {
      this.handleNextCaseAfterSave();
    });
    if (this.enableTWAINDriver) {
      eventBus.$on(TWAIN_TRANSFER, data => {
        console.log("Image scanned event from header", data);
        if (!this.isScanning) {
          this.isScanning = true;
        }
      });
    }
    eventBus.$on(ENTER_ORDER_DEMO, orderNumber => {
      this.inputOrderNumber = orderNumber;
    });
    eventBus.$on(SIGNED_CASE_FROM_PDF, () => {
      this.handleNavigateAfterSign();
    });
    eventBus.$on(SIGN_CASE_FROM_SHORTKEY, () => this.handleSignCase(this.caseId));
    this.$nextTick(() => {
      if (this.caseDetails.orderNumber) {
        this.inputOrderNumber = this.caseDetails.orderNumber;
      }
    });
  },
  beforeDestroy() {
    if (this.shouldPrintOrders) {
      this.autoPrintOrders();
    }
    eventBus.$off(SCANNED_IMAGE);
    eventBus.$off(ENTER_ORDER_DEMO);
    eventBus.$off(LAST_SPECIMEN_SAVED);
    eventBus.$off(SIGNED_CASE_FROM_PDF);
    eventBus.$off(SIGN_CASE_FROM_SHORTKEY);
    if (this.enableTWAINDriver) {
      eventBus.$off(TWAIN_TRANSFER);
    }
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!to.fullPath.includes("accession")) {
      this.$store.commit(
        "accessionStore/setPreviousCaseDetails",
        this.$store.state.accessionStore.caseDetails
      );
      if (this.editType && this.reasonForChange && this.currentUser?.id) {
        return window
          .confirm(
            "Are you sure you want to discard any data entered and return to previous status?"
          )
          .then(res => {
            if (res) {
              this.$store.dispatch("accessionStore/ammendCase", {
                editType: null,
                reasonForChange: null
              });
              next();
            }
          });
      }
    }
    return next();
  },
  methods: {
    ...mapMutations(["setDistributionTarget", "toggleDistributionPopup"]),
    toggleReReport() {
      this.$store.commit("accessionStore/setReReportPopup", !this.reReportPopup);
    },
    handleChangeNumber() {
      this.isChangingNumber = true;
    },
    openTwain() {
      this.isScanning = true;
      if (Number.isInteger(this.selectedTWAINDevice)) {
        TWAINDriver.acquireImage(this.selectedTWAINDevice);
      }
    },
    dateFormatter(data) {
      if (data instanceof Date) {
        return format(data, "MM/dd/yy");
      }
      data = new Date(data);
      if (isValid(data)) {
        return format(data, "MM/dd/yy");
      }
      return "";
    },
    dateTimeFormatter(data) {
      try {
        if (data) {
          const formattedDate = formatDatetimeCell(data);
          return format(formattedDate, "MM/dd/yy hh:mm a");
        }
      } catch (error) {
        console.log(error);
      }
      return "";
    },
    async handleSignCase(caseId) {
      if (this.isLoading) {
        return;
      }
      if (this.editType) {
        return;
      }
      if (this.warnIfProxy && this.isProxy) {
        const confirm = await window.confirm(
          "You are signing this case out as Proxy. Do you wish to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      if (!this.isSigned && !this.caseDetails.collectedOn) {
        this.isCollectedPopupOpen = true;
        return;
      }
      if (!this.isSigned && this.availableLabLocations.length > 1 && !this.labLocationId) {
        this.isLocationPopupOpen = true;
        return;
      }
      if (
        !this.isSigned &&
        bitEnumToArray(this.labSettings.SpecimenClinicalRequired).includes(
          SpecimenFieldRequiredEnum.Sign
        )
      ) {
        for (const specimen of this.specimens) {
          if (!getTextFromHtml(specimen.clinical)) {
            const confirm = await window.confirm(
              "One or more specimens does not have clinical text. Do you still wish to sign out this case?"
            );
            if (!confirm) {
              return;
            }
          }
        }
      }
      if (
        !this.isSigned &&
        bitEnumToArray(this.labSettings.SpecimenGrossRequired).includes(
          SpecimenFieldRequiredEnum.Sign
        )
      ) {
        for (const specimen of this.specimens) {
          let isGrossed = Boolean(specimen?.gross);
          if (isGrossed && specimen?.protocolId) {
            const protocol = await MacrosApi.getMacroDetails(specimen.protocolId);
            if (
              protocol?.gross &&
              getTextFromHtml(protocol.gross) === getTextFromHtml(specimen.gross)
            ) {
              isGrossed = false;
            }
          }
          if (!isGrossed) {
            const confirm = await window.confirm(
              "One or more specimens has not been grossed. Do you still wish to sign out this case?"
            );
            if (!confirm) {
              eventBus.$emit(OPEN_UNGROSSED_SPECIMEN, specimen.id);
              return;
            }
          }
        }
      }
      let tildeCheckClear = await this.checkForTildes();
      if (tildeCheckClear) {
        if (!this.isSigned && this.labSettings.ConfirmPrelimSignOuts && this.caseHolds?.length) {
          const confirm = await window.confirm(
            "This case has a hold on it. Are you sure you want to sign this case as prelim?"
          );
          if (!confirm) {
            return;
          }
        }
        try {
          this.isLoading = true;
          const confirmStatus = await this.getPhysicianPopup(4); //Case SignOut
          if (!confirmStatus) {
            this.isLoading = false;
            window.notify("User cancelled action.", "error");
            return;
          }
          const response = await this.$store.dispatch("accessionStore/handleSignCase", {
            caseId,
            labLocationId: this.currentLabLocation,
            caseStatus: this.caseHeader.status
          });
          if (
            response &&
            [
              CaseStatusEnum.Signed,
              CaseStatusEnum.SignedOnHold,
              CaseStatusEnum.SignedAgain
            ].includes(response?.status)
          ) {
            this.handleNavigateAfterSign();
          }
        } catch (error) {
          handleErrors(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleViewPathReport() {
      const { caseId } = this.$route.params;
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    async downloadCaseReport() {
      const { caseId } = this.$route.params;
      const report = await ReportsApi.getSSRSReport({
        caseId,
        format: "pdf",
        outputFormat: null,
        StartPage: 0
      });
      const blob = new Blob([report], {
        type: "application/pdf"
      });
      const url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `Path_Report_${format(new Date(), "MM/dd/yyyy")}.pdf`;
      a.click();
      PathReportMessagesApi.addPathReportMessage({ caseIds: [caseId], method: 6 });
    },
    async handleCaseDelete() {
      const isResulted = this.caseHeader.status < 9;
      const messageHtml = `<h6>Case: ${
        this.caseHeader.caseNumber ? this.caseHeader.caseNumber : ""
      }</h6><br><h6>Patient: ${this.caseHeader.name ? this.caseHeader.name : ""}</h6><br>${
        isResulted
          ? "<p>Are you sure you want to delete this case?<br> Click Yes to delete the case or No to abort.</p>"
          : "The case has already been Resulted.<br> Click Yes to delete the case or No to abort."
      }`;
      const dialogFunc = dialog(messageHtml, "IntelliPath Pro", "Yes", "No");
      const confirm = (await dialogFunc.show()) || false;
      if (confirm) {
        return CasesApi.deleteCase({ caseId: this.caseDetails.caseId })
          .then(() => {
            const logItem = createLogItem(this.caseDetails, AuditLogItems.DeleteAccession);
            AuditLogApi.insertLogMessage(logItem);
            window.notify(`${this.caseHeader.caseNumber} has been deleted`);
            this.$router.push({ name: "Home" });
          })
          .catch(() => {
            window.notify(
              `Server Error: Could not delete case ${this.caseHeader.caseNumber}.`,
              "error"
            );
          });
      }
      return;
    },
    async handleDistribution(type) {
      if (!this.isSigned) {
        if (type === DistributionMethodsEnum.HL7) {
          window.alert(
            '<div class="text-center">This accession has not been signed out and cannot be distributed through HL7.</div>'
          );
          return;
        }
        const confirm = await window.confirm(
          `<div class="text-center">
          The accession has not been signed out. <br> Are you sure you want to distribute this report?
          </div>`
        );
        if (!confirm) {
          return;
        }
      }
      this.isDistributionOpen = true;
      this.distributionMethod = type;
    },
    toggleReason() {
      this.isEditReasonOpen = true;
    },
    toggleHistory() {
      this.isHistoryOpen = true;
    },
    async checkForTildes() {
      if (!this.labSettings.SignOutTildeCheck) {
        return true;
      }
      let fieldsWithTildes = [];
      for (const specimen of this.specimens) {
        const freeTextFields = [
          "diagnosis",
          "clinical",
          "gross",
          "microscopic",
          "caseNotes",
          "notes"
        ];
        for (const field of freeTextFields) {
          if (specimen[field]?.includes("~")) {
            fieldsWithTildes.push("Specimen " + specimen.specimenOrder + ": " + field);
          }
        }
      }
      if (fieldsWithTildes.length) {
        const confirm = await window.confirm(
          "The following free text fields contains tildes ( ~ ) that may be a placeholder from a macro:<br><br>" +
            fieldsWithTildes.join("<br>") +
            "<br><br>Do you wish to sign this case out without replacing them? <br><br>Click <strong>YES</strong> to proceed without replacing.<br>Click <strong>NO</strong> to go back and replace them."
        );
        return confirm;
      }
      return true;
    },
    async scanCase(barcode) {
      const scan = scanCaseBarcode(barcode);
      if (!scan) {
        return;
      }
      this.devlog("Scanned barcode in Case Header", barcode);
      const { caseNumber, specimenOrder } = scan;
      if (caseNumber?.toUpperCase() === this.caseDetails.caseNumber) {
        return;
      }
      if (specimenOrder && ["specimen", "result"].includes(this.accessionMode)) {
        this.$store.commit("accessionStore/setSpecimenToLoad", specimenOrder);
      }
      const matchingCases = await CasesApi.searchStore.load({
        filter: ["accessionNumber", caseNumber]
      });
      if (matchingCases.length === 1) {
        eventBus.$emit(SCAN_IN_CASE, barcode);
        this.$router.push({ name: this.$route.name, params: { caseId: matchingCases[0].caseId } });
      }
    },
    closeNextCase() {
      this.isNextCaseOpen = false;
    },
    handleNextCaseAfterSave() {
      // For users who still have this as a boolean
      const legacyCondition =
        this.nextCasePopup === true && !["result", "signout"].includes(this.accessionMode);
      if (this.nextCasePopup === AutoNextCaseEnum.AfterSave || legacyCondition) {
        // For those who have updated it
        this.handleNextCase();
      }
    },
    handleSelectLabLocation() {
      this.isLocationPopupOpen = false;
      this.handleSignCase(this.caseId);
    },
    async handleSetCollected(date) {
      this.isCollectedPopupOpen = false;
      this.caseDetails.collectedOn = date;
      await this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...this.caseDetails,
        patientAccountNumber: this.caseDetails.acctNumber
      });
      this.handleSignCase(this.caseDetails.caseId);
    },
    handleNavigateAfterSign() {
      // Boolean accounts for users who still have the old user setting.
      if ([AutoNextCaseEnum.AfterSign, true].includes(this.nextCasePopup)) {
        this.handleNextCase();
      } else if (this.returnToQuickSignout) {
        this.$router.push("/quicksignout");
      } else {
        this.$router.push("/");
      }
    },
    handleTriggerSignOut(caseId) {
      if (this.$route.name === "SpecimenResults" && !this.isSigned) {
        eventBus.$emit(PRESSED_SIGNOUT_KEY);
      } else {
        this.handleSignCase(caseId);
      }
    },
    hasSlideImages(specimenId) {
      if (this.slideImages?.length > 0) {
        const slides = this.slideImages.find(e => e.specimenId === specimenId);
        return slides?.slideImageUrls?.length > 0 || slides?.specimenSlideImageUrl;
      }
      return false;
    },
    launchSpecimenImages(specimenId) {
      eventBus.$emit(OPEN_SLIDE_IMAGES, specimenId);
    },
    handleShortKey({ srcKey }) {
      // Shortkey behavor changes when modal open in other component.
      const modalStop = isModalOpen();
      switch (srcKey) {
        case "nextCaseShortkey":
          if (modalStop) {
            return;
          }
          this.isNextCaseOpen = !this.isNextCaseOpen;
          break;
        case "h":
          if (this.isHistoryOpen) {
            this.isHistoryOpen = false;
          } else if (!modalStop) {
            this.isHistoryOpen = true;
          }
          break;
        case "p":
          if (modalStop) {
            return;
          }
          this.openTwain();
          break;
        case "t":
          if (this.showTaskModal) {
            this.showTaskModal = false;
          } else if (!modalStop) {
            this.showTaskModal = true;
          }
          break;
        case "b":
          if (this.hasProstate) {
            this.toggleProstatePopup();
          }
          break;
        case "x":
        case "f12":
          if (!modalStop) {
            this.handleTriggerSignOut(this.caseId);
          }
          break;
      }
    },
    openEditProvider() {
      if (this.permissions.ContactCreateEdit) {
        this.toggleContactForm();
      }
    },
    toggleContactForm() {
      this.isContactFormOpen = !this.isContactFormOpen;
    },
    async handleAutoMatchHistory() {
      const numberOfCases = await CasesApi.checkHistory(this.caseDetails.caseId);
      if (numberOfCases !== 1) {
        return;
      }
      const payload = createPatientMatchPayload(this.caseDetails);
      if (!payload) {
        return;
      }
      let { data } = await CasesApi.matchPatient(payload);
      if (data?.length > 1) {
        this.patientMatchOptions = data.filter(e => e.id !== this.caseDetails.patientId);
        this.isMatching = true;
        this.isAutoCheckHistory = true;
      }
    },
    async closeMatchingModal() {
      this.isMatching = false;
      this.patientMatchOptions = [];
    },
    async handleMatchPatient(patientRecord) {
      let newCaseDetails = {
        ...this.caseDetails,
        patientId: patientRecord.id,
        patientAccountNumber: this.caseDetails.acctNumber
      };
      const fieldMap = [
        ["patientSSN", "ssn"],
        ["patientDOB", "dateOfBirth"],
        ["patientMRN", "mrn"],
        ["patientSex", "sex"],
        ["patientAccountNumber", "accountNumber"],
        ["patientFirstName", "firstName"],
        ["patientLastName", "lastName"],
        ["patientRace", "race"],
        ["alternateId", "alternateId"],
        ["patientMaidenName", "maidenName"],
        ["suffix", "suffix"]
      ];
      for (const item of fieldMap) {
        if (!newCaseDetails[item[0]] && ![null, undefined, ""].includes(patientRecord[item[1]])) {
          newCaseDetails[item[0]] = patientRecord[item[1]];
        }
      }
      const { phoneNumbers, emails, addresses } = newCaseDetails;
      if (!emails?.length && patientRecord.emails) {
        newCaseDetails.emails = patientRecord.emails;
      }
      if (!addresses?.length && patientRecord.addresses) {
        newCaseDetails.addresses = patientRecord.addresses;
      }
      if (!addresses?.length && patientRecord.address) {
        newCaseDetails.addresses = patientRecord.address;
      }
      if (!phoneNumbers?.length && patientRecord.phoneNumbers) {
        newCaseDetails.phoneNumbers = patientRecord.phoneNumbers;
      }
      try {
        await this.$store.dispatch("accessionStore/updateCaseDetails", newCaseDetails);
        window.notify("Linked case to patient record.");
        this.isAutoCheckHistory = false;
        this.closeMatchingModal();
        this.$store.dispatch("accessionStore/getCaseCounts", this.caseDetails.caseId);
        this.$store
          .dispatch("accessionStore/getCaseDetails", this.caseDetails.caseId)
          .then(() => eventBus.$emit(UPDATE_CASE_DEMOGRAPHICS));
      } catch (error) {
        handleErrors(error);
      }
    },
    async handleNextCase() {
      if (this.nextCaseType) {
        // Auto navigate to next case
        try {
          const nextCaseObject = await CasesApi.getNextCaseNumber(this.caseDetails.caseId);
          if (!nextCaseObject?.caseId) {
            window.notify("No subsequent cases available.", "error");
          } else if (nextCaseObject?.caseId) {
            let destinationTab = this.$route.name;
            if (this.nextCaseUseDashboardMode) {
              switch (this.dashboardMode) {
                case "specimen":
                  destinationTab = "Specimen";
                  break;
                case "result":
                  destinationTab = "SpecimenResults";
                  break;
                case "signout":
                  destinationTab = "SpecimenResults";
                  break;
                case "transactions":
                  destinationTab = "accesionTransactions";
                  break;
                case "insurance":
                  destinationTab = "accessionInsurance";
                  break;
                case "distribution":
                  destinationTab = "Distribution";
                  break;
                case "history":
                  destinationTab = "CaseHistory";
                  break;
                default:
                  destinationTab = "CaseView";
                  break;
              }
            }
            this.$router.push({ name: destinationTab, params: { caseId: nextCaseObject.caseId } });
          }
        } catch (error) {
          handleErrors(error);
        }
        return;
      }
      this.isNextCaseOpen = true;
    },
    getProtocols(prefixId) {
      DropdownApi.getProtocol(prefixId).then(res => {
        this.protocolOptions = res || [];
      });
    },
    toggleProstatePopup() {
      this.isProstatePopupOpen = !this.isProstatePopupOpen;
    },
    async handleSubmitProstate(data) {
      if (data?.length) {
        this.isLoading = true;
        try {
          await this.$store.dispatch("accessionStore/updateProstateSpecimens", data);
        } catch (error) {
          handleErrors(error);
        } finally {
          this.isLoading = false;
          window.notify("Specimens updated.");
        }
      }
      this.toggleProstatePopup();
    },
    handleSaveClick() {
      eventBus.$emit(SAVE_FROM_CASE_HEADER);
    },
    refreshWorkRate() {
      CasesApi.getWorkRate(new Date().getTimezoneOffset()).then(res => {
        this.workRate.casesResulted = res.qtyCasesResulted;
        this.workRate.specimensResulted = res.qtySpecimensResulted;
        this.workRate.casesSigned = res.qtyCasesSignedPrelim + res.qtyCasesSignedFinal;
        this.workRate.specimensSigned = res.qtySpecimensSigned;
      });
    },
    async declineHistoryMatch() {
      const confirm = await window.confirm(
        "Are you sure you do not want to match this case to an existing patient?"
      );
      if (!confirm) {
        return;
      } else {
        const logItem = createLogItem(this.caseDetails, AuditLogItems.Other);
        logItem.comments = `Declined to match patient history.\n\nPayload: ${JSON.stringify(
          createPatientMatchPayload(this.caseDetails),
          null,
          2
        )}\n\nMatches: ${JSON.stringify(this.patientMatchOptions, null, 2)}`;
        AuditLogApi.insertLogMessage(logItem);
        this.closeMatchingModal();
      }
    },
    autoPrintOrders() {
      if (this.shouldPrintOrders) {
        this.$store.commit("accessionStore/setShouldPrintOrders", false);
        this.$store.dispatch("accessionStore/autoPrintOrders", this.staticCaseId);
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      reReportPopup: state => state.accessionStore.reReportPopup,
      editType: state => state.accessionStore.editType,
      reasonForChange: state => state.accessionStore.reasonForChange,
      caseHeader: state => state.accessionStore.caseHeader,
      caseDetails: state => state.accessionStore.caseDetails,
      historyCount: state => state.accessionStore.caseCounts.historyCount,
      legacyHistoryCount: state => state.accessionStore.caseCounts.legacyHistoryCount,
      specimens: state => state.accessionStore.specimens,
      selectedCasesForDistribution: state => [state.accessionStore.caseDetails.caseId],
      headerLoading: state => state.accessionStore.headerLoading,
      currentSpecimen: state => state.accessionStore.currentSpecimen ?? {},
      enableTWAINDriver: state => state.applicationSettings.enableTWAINDriver,
      selectedTWAINDevice: state => state.applicationSettings.selectedTWAINDevice,
      nextCaseType: state => state.applicationSettings.nextCaseType,
      nextCasePopup: state => state.applicationSettings.nextCasePopup,
      accessionMode: state => state.applicationSettings.accessionMode,
      sexes: state => state.dropdowns.sexes,
      races: state => state.dropdowns.races,
      priorities: state => state.dropdowns.priorities,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      caseHolds: state => state.accessionStore.caseHolds,
      returnToQuickSignout: state => state.returnToQuickSignout,
      slideImages: state => state.accessionStore.slideImages,
      nextCaseUseDashboardMode: state => state.applicationSettings.nextCaseUseDashboardMode,
      dashboardMode: state => state.applicationSettings.accessionMode,
      casePrefix: state => state.accessionStore.casePrefix,
      warnIfProxy: state => state.applicationSettings.warnIfProxy,
      shouldPrintOrders: state => state.accessionStore.shouldPrintOrders
    }),
    ...mapGetters("accessionStore", ["editTypeName", "primaryPathologist"]),
    ...mapGetters(["permissions"]),
    showScanButton() {
      return this.enableTWAINDriver;
    },
    signButton() {
      const { status } = this.caseHeader;
      if (
        [CaseStatusEnum.SignedOnHold, CaseStatusEnum.Signed, CaseStatusEnum.SignedAgain].includes(
          status
        )
      ) {
        return { icon: "unlink", message: "Unsign" };
      } else {
        return { icon: "edit", message: "Sign" };
      }
    },
    canSignOut() {
      if (!this.permissions.CaseSignout && !this.permissions.CaseSignoutByProxy) {
        return false;
      }
      const { isPathologist, id } = this.currentUser;
      if ((isPathologist && id === this.primaryPathologist?.guid) || this.isProxy) {
        const { status } = this.caseHeader;
        if (
          [
            CaseStatusEnum.ResultedOnHold,
            CaseStatusEnum.SignedOnHold,
            CaseStatusEnum.Resulted,
            CaseStatusEnum.Signed,
            CaseStatusEnum.ResultedAgain,
            CaseStatusEnum.SignedAgain
          ].includes(status)
        ) {
          return true;
        }
      }
      return false;
    },
    signOutKeys() {
      return {
        x: altKey("x"),
        f12: altKey("f12")
      };
    },
    shortenedAccessionNumber() {
      return shortenAccessionNumber(this.caseHeader.caseNumber);
    },
    specimenCount() {
      if (this.caseHeader.specimenCount > this.specimens.length) {
        return this.caseHeader?.specimenCount;
      }
      return this.specimens.length;
    },
    isSigned() {
      if ([7, 8, 10, 11, 13, 14].includes(this.caseHeader.status)) {
        return true;
      }
      return false;
    },

    currentSpecimenOrder() {
      if (this.currentSpecimen) {
        return this.currentSpecimen.specimenOrder;
      }
      return "";
    },
    casePhoneNumber() {
      if (this.caseHeader.phone) {
        const rawPhone = this.caseHeader.phone
          .replace(/[^\d]/g, "")
          .replace(/(\d{3})(\d{3})(\d+)/, "($1)-$2-$3");
        return rawPhone;
      }
      return "";
    },
    providerPhone() {
      if (this.caseHeader.providerPhone) {
        const rawPhone = this.caseHeader.providerPhone
          .replace(/[^\d]/g, "")
          .replace(/(\d{3})(\d{3})(\d+)/, "($1)-$2-$3");
        return rawPhone;
      }
      return "";
    },
    specimenNumber() {
      if (this.currentSpecimenOrder) {
        return this.specimens.findIndex(e => e.specimenOrder === this.currentSpecimenOrder) + 1;
      }
      return "";
    },

    status() {
      return this.caseHeader.status;
    },
    priority() {
      const priority = this.priorities.find(e => {
        return e.id === Number(this.caseHeader.priority);
      });
      let htmlClass = "green";
      if (this.caseHeader.priority === 1) {
        htmlClass = "red";
      }
      if (this.caseHeader.priority === 2 || this.caseHeader.priority === 4) {
        htmlClass = "yellow";
      }
      return {
        name: priority?.displayName,
        class: priority?.settingType ? `bg-${priority.settingType}` : htmlClass
      };
    },
    patientRace() {
      if (!Number.isNaN(parseInt(this.caseHeader.race))) {
        const race = this.races.find(e => e.id === Number(this.caseHeader.race));
        if (race) {
          return race.displayName;
        }
      }
      return "";
    },
    patientSex() {
      const sex =
        typeof this.caseHeader.sex === "number"
          ? this.sexes.find(e => e.id === Number(this.caseHeader.sex))
          : null;
      if (sex) {
        return sex.displayName[0];
      } else {
        return "";
      }
    },
    caseStatus() {
      const statusEnum = [
        "N/A",
        "Acc",
        "Demo",
        "Spec",
        "Gross",
        "Orders",
        "Resulted on Hold",
        "Signed on Hold",
        "Reported Prelim",
        "Resulted",
        "Signed",
        "Reported",
        "Resulted Again",
        "Signed Again",
        "Re-released"
      ];
      return statusEnum[this.caseHeader?.status];
    },
    caseId() {
      return this.$route.params.caseId;
    },
    currentRouteName() {
      return this.$route.name;
    },
    orderLookupNumber() {
      if (this.inputOrderNumber === null) {
        return this.caseDetails.orderNumber;
      }
      return this.inputOrderNumber;
    },
    isDemographics() {
      return this.$route.name === "CaseView";
    },
    showManualAccession() {
      return (
        this.caseHeader.caseCreationMethod === CaseCreationMethodEnum.Manual &&
        this.labSettings.ShowCreationMethod
      );
    },
    labLocationId: {
      get() {
        return this.currentLabLocation;
      },
      set(value) {
        this.$store.dispatch("setCurrentLabLocation", value);
        return value;
      }
    },
    displaySSN() {
      if (this.caseHeader?.ssn) {
        return formatSSN(this.caseHeader.ssn);
      }
      return "";
    },
    siteDisplay() {
      return this.currentSpecimen.site || "";
    },
    clinicalDisplay() {
      const text = getTextFromHtml(this.currentSpecimen.clinical);
      if (text?.length) {
        // if (text.length > 50) {
        //   return text.slice(0, 100) + "...";
        // }
        return text;
      }
      return "";
    },
    primaryContactData() {
      if (this.primaryProvider?.contact) {
        return this.primaryProvider.contact;
      }
      return {};
    },
    isReported() {
      return [
        CaseStatusEnum.ReportedPrelim,
        CaseStatusEnum.Reported,
        CaseStatusEnum.ReReleased
      ].includes(this.caseDetails.status);
    },
    hasProstate() {
      for (const specimen of this.specimens) {
        if (specimen?.protocolId) {
          const protocol = this.protocolOptions.find(e => e.id === specimen.protocolId);
          if (protocol && protocol?.isProstate) {
            return true;
          }
        }
      }
      return false;
    },
    showSaveButton() {
      if (this.isSigned && !this.editType) {
        return false;
      }
      const routeName = this.$route.name;
      if (routeName === "CaseView") {
        return true;
      }
      if (["SpecimenResults", "Specimen"].includes(this.$route.name)) {
        return Boolean(this.currentSpecimenOrder);
      }
      return false;
    },
    workRateHtml() {
      const { casesResulted, specimensResulted, casesSigned, specimensSigned } = this.workRate;
      let returnArray = [];
      if (casesResulted || specimensResulted) {
        returnArray.push(
          ["<b>R:</b>", casesResulted + "c,", specimensResulted + "s"]
            .map(e => `<td>${e}</td>`)
            .join("")
        );
      }
      if (casesSigned || specimensSigned) {
        returnArray.push(
          ["<b>S:</b>", casesSigned + "c,", specimensSigned + "s"]
            .map(e => `<td>${e}</td>`)
            .join("")
        );
      }
      if (returnArray.length) {
        return (
          "<table>" +
          returnArray.map(e => `<tr style='line-height:1em;'>${e}</tr>`).join("") +
          "</table>"
        );
      }
      return "";
    }
  },
  watch: {
    showTaskModal: {
      immediate: true,
      handler() {
        if (this.permissions.TaskView) {
          TaskApi.searchStore
            .load({ filter: [["caseId", this.caseId], "and", ["isCompleted", false]] })
            .then(res => {
              this.caseTasks = res || [];
            });
        }
      }
    },
    "caseDetails.pathologists": {
      immediate: true,
      handler(nv) {
        if (nv?.length) {
          const primaryPathologist = nv.find(e => e?.isPrimary);
          if (primaryPathologist && primaryPathologist.guid !== this.currentUser.id) {
            this.isProxy = checkIfProxy(primaryPathologist.guid);
          }
        } else {
          this.isProxy = false;
        }
      }
    },
    "caseDetails.shouldCheckHistory": {
      // This is sent from backend if:
      // * Case was accessioned by interface@netsofusa.com
      // * HasHistoryBeenChecked is null or 0
      immediate: true,
      handler(nv, ov) {
        if (nv && !ov) {
          this.handleAutoMatchHistory();
        }
      }
    },
    "casePrefix.id": {
      immediate: true,
      handler(nv) {
        this.getProtocols(nv);
      }
    },
    "caseDetails.status": {
      immediate: true,
      handler(nv, ov) {
        if (nv !== ov) {
          this.refreshWorkRate();
        }
      }
    },
    "caseDetails.caseId": {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (this.shouldPrintOrders && nv && this.staticCaseId && nv !== this.staticCaseId) {
            this.autoPrintOrders();
          }
          this.staticCaseId = nv;
        }
      }
    }
  },
  created() {
    this.$store.dispatch("dropdowns/getRaces");
    this.$store.dispatch("dropdowns/getPriorities");
    this.$store.dispatch("dropdowns/getSexes");
  }
};
</script>

<style lang="scss" scoped>
span {
  font-weight: 400px;
  line-height: 24px;
  font-size: 15px;
}
.header_wrapper {
  position: relative;
}

.dates-block {
  .header-property {
    display: grid;
    grid-template-columns: 1fr 2fr;
    .header-property_date {
      font-size: 16px;
      justify-self: end;
    }
  }
}

.header-property {
  &_name {
    font-weight: 600;
    flex-grow: 2;
    & > span {
      font-weight: normal;
    }
  }
  &_date {
    flex-shrink: 0;
  }
}
.task-container {
  width: 75vw;
}
.status {
  background-color: #bdcdd3;
}
.text-lg {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 2rem;
}

.case-header {
  position: relative;
  line-height: 24px;

  border-radius: 5px;
  background: $primary-light;
  .border-right {
    border-color: lightgray !important;
  }
  .text-xl {
    font-size: 1.225rem !important;
  }

  .number_block {
    margin: 0 1rem;
  }

  .button-block {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;
    width: 25%;
    padding: 0 0.5rem;
  }
  .status-header {
    font-weight: 500;
    &_caseNumber {
      font-size: 2rem;
      letter-spacing: 1.5px;
      line-height: 36px;
    }
    &_currentSpecimen {
      font-size: 1.5rem;
      margin: 0.25rem 0;
    }
  }
}

/* If the screen size is 1200px wide or more */
@media screen and (min-width: 1025px) {
  .number_block {
    flex-grow: 0;
  }
  .date-block {
    width: 10%;
    padding: 0 2rem;
    flex-grow: 1;
  }
  .name-block {
    flex-grow: 4;
  }
  .button-block {
    /* d-flex flex-wrap col-3  justify-content-end */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-grow: 0;
  }
}

@media screen and (max-width: 1024px) {
  .case-header {
    flex-wrap: wrap;
    padding: 0 0.2rem;
  }
  .number_block {
    display: inline-flex;
    width: 40%;
  }
  .date-block {
    /* width: 20vw; */
    width: 40%;
  }
  .name-block {
    /* col */
    flex-grow: 1;
    display: inline;
  }
  .clinical-display .site-display {
    text-align: left !important;
  }
}

/* If the screen size is 700px wide or less */
// @media screen and (max-width: 800px) {
//   .case-header {
//     flex-wrap: wrap;
//     padding: 0.25rem;
//     &.collapsed {
//       display: none;
//     }
//   }
//   #collapse {
//     display: block;
//     &.collapsed {
//       top: -10px;
//       /* position: */
//     }
//   }
//   // .number_block {
//   // }
//   .date-block {
//     width: 20vw;
//     /* width: 250px; */
//   }
//   // .name-block {
//   // }
// }
</style>
