<template>
  <!-- SIDEBAR -->
  <div class="sidebar-section">
    <button
      v-for="(tab, index) of activeTabs"
      v-shortkey="shortkeyAlt(index)"
      @shortkey="handleTabClick(tabs[tab])"
      :key="tab + index"
      :disabled="tabs[tab].isDisabled || !isAccessioned || routeName === tabs[tab].route"
      class="btn w-100"
      @click="handleTabClick(tabs[tab])"
      :id="tab + 'tab'"
    >
      <icon :icon="tabs[tab].icon" />
      <span
        v-if="tabs[tab].count"
        :data-testid="`${tab}-count`"
        :class="`count_icon m-0 ${tabs[tab].class ? tabs[tab].class : ''}`"
        >{{ tabs[tab].count }}</span
      >
      <span
        v-if="tabs[tab].countLower"
        :data-testid="`${tab}-count-lower`"
        :class="`count_icon lower m-0`"
        >{{ tabs[tab].countLower }}</span
      >
      <span class="d-block text-center text-capitalize">
        {{ tab }}
      </span>
    </button>
    <modal :status="isLabelsOpen" @close="toggleModal('labels')">
      <PrintLabelsPopup :caseIdFilter="caseDetails.caseId" />
    </modal>
    <modal :status="isSlidesOpen" @close="toggleModal('slides')">
      <slides-popup :caseNumberFilter="caseDetails.caseNumber" />
    </modal>
  </div>
</template>

<script>
import { altKey } from "@/modules/helpers";
import { mapState, mapGetters } from "vuex";
import Icon from "../common/Icon.vue";
import Modal from "../common/Modal.vue";
import PrintLabelsPopup from "../PrintLabelsPopup.vue";
import SlidesPopup from "../SlidesPopup.vue";
import { CaseEditTypeEnum } from "@/modules/enums";

export default {
  components: { Icon, Modal, PrintLabelsPopup, SlidesPopup },
  name: "CaseSidebar",

  data() {
    return {
      isAccessioned: true,
      isSlidesOpen: false,
      isLabelsOpen: false
    };
  },
  created() {
    const { caseId } = this.$route.params;
    this.$store.dispatch("accessionStore/getCaseCounts", caseId);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      requisitionLabels: state => state.requisitionLabels,
      caseDetails: state => state.accessionStore.caseDetails,
      distributionCount: state => state.accessionStore.caseCounts.distributionCount,
      historyCount: state => state.accessionStore.caseCounts.historyCount,
      insuranceCount: state => state.accessionStore.caseCounts.insuranceCount,
      resultsCount: state => state.accessionStore.caseCounts.resultsCount,
      specimenCount: state => state.accessionStore.caseCounts.specimenCount,
      legacyHistoryCount: state => state.accessionStore.caseCounts.legacyHistoryCount,
      txCount: state => state.accessionStore.caseCounts.txCount,
      ShowLegacyCaseCount: state => state.labSettings.ShowLegacyCaseCount
    }),
    ...mapGetters(["permissions"]),
    ...mapGetters("accessionStore", ["isCytology"]),

    isNonDiagnosticChange() {
      if (
        [CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(
          this.caseDetails?.editType
        )
      ) {
        return true;
      }
      return false;
    },

    tabs() {
      return {
        demographics: {
          active: this.permissions.CaseView,
          icon: "users",
          route: "CaseView"
        },
        specimens: {
          active: this.permissions.CaseSpecimenView && !this.isCytology,
          icon: "vial",
          route: "Specimen",
          count: this.specimenCount
        },
        //! TODO Convert this into a modal route.
        "Molecular Tests": {
          active: this.permissions.CaseSpecimenView && this.isCytology,
          icon: "tachometer-alt",
          route: "MolecularTest"
        },
        "Clinical Data": {
          active: this.permissions.CaseSpecimenView && this.isCytology,
          icon: "vial",
          route: "ClinicalData",
          count: this.specimenCount
        },
        results: {
          active: this.permissions.CaseResultsView,
          icon: "clipboard-check",
          route: this.isCytology ? "CytologyResults" : "SpecimenResults",
          isDisabled: this.isNonDiagnosticChange,
          count: this.resultsCount
        },
        transactions: {
          active: this.permissions.CaseTransactionView,
          icon: "exchange-alt",
          route: "accesionTransactions",
          count: this.txCount
        },
        insurance: {
          active: this.permissions.CaseInsuranceView,
          icon: "umbrella",
          route: "accessionInsurance",
          count: this.insuranceCount
        },
        history: {
          active: this.permissions.CaseHistoryView,
          route: "CaseHistory",
          icon: "history",
          count: this.historyCount,
          class: " font-bold bg-danger text-white history",
          countLower: this.ShowLegacyCaseCount ? this.legacyHistoryCount : 0
        },
        distribution: {
          active: this.permissions.CaseHistoryView,
          route: "Distribution",
          icon: "project-diagram",
          count: this.distributionCount
        },
        activity: {
          active: this.permissions.AuditLogView,
          route: "CaseActivityLog",
          icon: "calendar-check"
        },
        "Cassette & Slide Printing": {
          active: this.permissions.CassetteSlidePrint,
          icon: "print",
          modal: "slides"
        },
        labels: {
          active: this.permissions.CaseCreateEdit,
          icon: "print",
          modal: "labels"
        }
      };
    },
    activeTabs() {
      return Object.keys(this.tabs).filter(e => this.tabs[e].active);
    },

    routeName() {
      return this.$route.name;
    }
  },
  methods: {
    handleTabClick(tab) {
      const { route, onClick, modal } = tab;
      if (onClick) {
        return onClick();
      }
      if (modal) {
        this.toggleModal(modal);
      }
      if (!route || !this.isAccessioned) {
        return;
      }
      const { name, params } = this.$route;
      if (name === route) {
        return;
      }
      if (!params.caseId) {
        this.$router.push({ name: route });
      } else {
        this.$router.push({
          name: route,
          params: { caseId: params.caseId }
        });
      }
    },
    handleDemographicsClick() {
      const { name, params } = this.$route;
      if (name === "CaseView") {
        return;
      }
      if (!params.caseId) {
        this.$router.push({ name: "Accession" });
      } else {
        this.$router.push({
          name: "CaseView",
          params: { caseId: params.caseId }
        });
      }
    },
    handleSpecimensClick() {
      const { name, params } = this.$route;

      if (name === "Specimen") {
        return;
      }
      if (!this.isAccessioned) {
        return;
      }
      this.$router.push({
        name: "Specimen",
        params: { caseId: params.caseId }
      });
    },
    handleResultsClick() {
      const { name, params } = this.$route;
      if (name === "SpecimenResults") {
        return;
      }
      if (!this.isAccessioned) {
        return;
      }
      this.$router.push({
        name: "SpecimenResults",
        params: { caseId: params.caseId }
      });
    },
    handleTransactionClick() {
      const { name, params } = this.$route;
      if (name === "accessionTransactions") {
        return;
      }
      if (!this.isAccessioned) {
        return;
      }
      this.$router.push({
        name: "accesionTransactions",
        params: { caseId: params.caseId }
      });
    },
    toggleModal(modalType) {
      if (modalType === "slides") {
        this.isSlidesOpen = !this.isSlidesOpen;
      }
      if (modalType === "labels") {
        this.isLabelsOpen = !this.isLabelsOpen;
      }
    },
    shortkeyAlt(index) {
      return altKey(index + 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-section {
  width: 100px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  & > button {
    position: relative;
    & > span {
      font-weight: 500;
      font-size: 0.85rem;
    }
    &.active {
      opacity: 0.5;
    }
    transition: opacity 150ms;
    background: $primary-light;
    border-radius: 3px;
    padding: 5px 0px;
    margin-bottom: 2.5px;
    text-align: center;
    font-size: 12px;
    & > svg {
      color: $primary;
      font-size: 24px;
    }
  }
}
.count_icon {
  position: absolute;
  top: -8px;
  right: -1px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 50%;
  background: $primary-light;
  font-weight: 600;
  font-size: 0.75rem;
  border: 1px solid $secondary;
  &.history {
    animation: pulse-red 1s infinite;
  }
  &.lower {
    position: absolute;
    top: initial;
    bottom: -2px;
    right: -3px;
  }
}
</style>
