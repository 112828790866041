import Dynamsoft from "dwt";
import { WEB_TWAIN_ID, SHOW_INSTALL_UI } from "@/modules/constants";
import Store from "@/store";
import eventBus, {
  TWAIN_TRANSFER,
  SCANNED_IMAGE,
  SCANNED_ALL_IMAGE,
  TWAIN_OPERATION_STARTS,
  TWAIN_OPERATION_ENDS
} from "@/modules/eventBus";

Dynamsoft.DWT.UseDefaultInstallUI = false;
Dynamsoft.DWT.ResourcesPath = "dwt-resources";
Dynamsoft.DWT.ProductKey = process.env.VUE_APP_TWAIN_PRODUCT_KEY;
const showInstallUI = (ProductName, InstallerUrl) => {
  eventBus.$emit(SHOW_INSTALL_UI, { installerUrl: InstallerUrl, productName: ProductName });
};
Dynamsoft.OnWebTwainNotFoundOnWindowsCallback = showInstallUI;
Dynamsoft.OnWebTwainNotFoundOnLinuxCallback = showInstallUI;
Dynamsoft.OnWebTwainNotFoundOnMacCallback = showInstallUI;
Dynamsoft.DWT.OnWebTwainPreExecute = function () {
  // Show your own progress indicator
  eventBus.$emit(TWAIN_OPERATION_STARTS);
  console.log("An operation starts!");
};
Dynamsoft.DWT.OnWebTwainPostExecute = function () {
  // Hide the progress indicator
  eventBus.$emit(TWAIN_OPERATION_ENDS);
  console.log("An operation ends!");
};
class TWAINDriver {
  constructor() {
    this.bWASM = false;
    this.isLoaded = false;
    this.scanAmount = 1;
  }
  load() {
    if (this.started) {
      return;
    }
    Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", () => {
      this.Dynamsoft_OnReady();
      this.isLoaded = true;
      this.started = true;
      console.log("Dynamsoft.DWT.OnWebTwainReady");
    });
    Dynamsoft.DWT.CreateDWTObjectEx(
      {
        WebTwainId: WEB_TWAIN_ID
      },
      () => {
        this.Dynamsoft_OnReady();
        this.isLoaded = true;
        this.started = true;
        console.log("Dynamsoft.DWT.OnWebTwainReady");
      }
    );
    console.log("Dynamsoft.DWT.CreateDWTObjectEx");
    Dynamsoft.DWT.Load();
  }

  Dynamsoft_OnReady() {
    const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
    DWObject.RegisterEvent("OnPostTransferAsync", data => {
      console.log("Dynamsoft.DWT.OnPostTransferAsync");
      eventBus.$emit(SCANNED_IMAGE, data);
    });
    DWObject.RegisterEvent("OnPostAllTransfers", function () {
      console.log("All images are transferred.");
      eventBus.$emit(SCANNED_ALL_IMAGE);
    });

    DWObject.RegisterEvent("OnPreTransfer", data => {
      console.log("Dynamsoft.DWT.OnPreTransfer");
      eventBus.$emit(TWAIN_TRANSFER, data);
      /** IP-1324 UPGRADE IMAGE QUALITY. Resolution width will be taken at face value from the twain device */
    });
    if (this.selectedTWAINDevice) {
      DWObject.SelectSourceByIndex(this.selectedTWAINDevice);
    }
  }
  get isEnabled() {
    return Store.state.applicationSettings.enableTWAINDriver;
  }
  get closeAfterTwain() {
    return Store.state.applicationSettings.closeAfterTwain ?? false;
  }
  get useScannerFeeder() {
    return Store.state.applicationSettings.useScannerFeeder ?? false;
  }
  get useDuplexScanning() {
    return Store.state.applicationSettings.useDuplexScanning ?? false;
  }

  async acquireImage(selectedSource) {
    const deviceConfiguration = {
      IfShowUI: true,
      IfShowIndicator: true,
      PixelType: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
      Resolution: 300,
      IfFeederEnabled: this.useScannerFeeder,
      IfDuplexEnabled: this.useScannerFeeder ? this.useDuplexScanning : false,
      IfDisableSourceAfterAcquire: this.closeAfterTwain,
      IfGetImageInfo: true,
      IfGetExtImageInfo: true,
      extendedImageInfoQueryLevel: 0,
      SelectSourceByIndex: selectedSource ?? 0
    };
    try {
      if (this.bWASM) {
        console.log("Scanning is not supported under the WASM mode!");
      } else {
        const DWObject = Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
        // const availableScanners = DWObject.SourceCount;
        // const selectedScanner = await DWObject.SelectSourceByIndexAsync(selectedSource);
        await DWObject.OpenSourceAsync();
        await DWObject.AcquireImageAsync(deviceConfiguration);
      }
    } catch (e) {
      console.log(e);
    }
  }
  disconnect() {
    Dynamsoft.DWT.DeleteDWTObject(WEB_TWAIN_ID);
    Dynamsoft.DWT.Unload();
    this.started = false;
  }
  started = false;
  containerId = null;
  get DWObject() {
    return Dynamsoft.DWT.GetWebTwain(WEB_TWAIN_ID);
  }
  get selectedTWAINDevice() {
    return Store.state.applicationSettings.selectedTWAINDevice;
  }
  get selectedResolution() {
    return Store.state.applicationSettings.selectedResolution;
  }
  get enableTWAINDriver() {
    return Store.state.applicationSettings.enableTWAINDriver;
  }
}

export default new TWAINDriver();
