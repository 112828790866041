<template>
  <div class="info-overlay-body">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0 mr-3">{{ shortCaseNumber }}</h4>
      <Icon icon="times" class="close-button" @click.native="$emit('close')" />
    </div>
    <div class="ml-2" v-if="!caseSpecimens.length && loadingText.text">
      <span :class="loadingText.class">{{ loadingText.text }}</span>
    </div>
    <div class="ml-1">
      <div v-for="specimen of caseSpecimens" v-bind:key="specimen.id" class="mb-1">
        <h5 v-if="caseSpecimens.length > 1" class="mb-0">
          <b>{{ specimen.specimenOrder }}:</b>
        </h5>
        <div class="ml-2">
          <div v-html="getSpecimenInfoHtml(specimen)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SpecimensApi } from "../services";
import Icon from "./common/Icon.vue";
import { shortenAccessionNumber } from "@/modules/helpers";
import eventBus, { REFRESH_INFO_OVERLAY } from "@/modules/eventBus";

export default {
  components: { Icon },
  props: ["caseDetails"],
  data() {
    return {
      caseSpecimens: [],
      loadingText: { text: "", class: "" }
    };
  },
  computed: {
    ...mapState({
      reportCaseId: state => state.report.caseId,
      specimens: state => state.accessionStore.specimens
    }),
    shortCaseNumber() {
      return shortenAccessionNumber(this.caseDetails?.caseNumber) || "";
    }
  },
  mounted() {
    this.getCaseSpecimens();
    eventBus.$on(REFRESH_INFO_OVERLAY, this.refreshCaseSpecimens);
  },
  beforeDestroy() {
    eventBus.$off(REFRESH_INFO_OVERLAY);
  },
  methods: {
    getCaseSpecimens() {
      if (this.specimens?.length) {
        this.caseSpecimens = this.specimens;
      } else {
        this.refreshCaseSpecimens();
      }
    },
    getSpecimenInfoHtml(specimen) {
      // This array will be joined to create the HTML output
      let textItems = [];
      for (const field of ["resultsMacros", "specimenTransactions", "icdCodes"]) {
        // Macros and CPT codes use displayName, ICD codes use displayCode
        const displayExpr = field === "icdCodes" ? "displayCode" : "displayName";
        // Default to none italicized
        let returnText = "<i>none</i>";
        if (specimen[field]?.length) {
          // Combine the display name/code of each item for the specimen
          if (field === "specimenTransactions") {
            returnText = specimen.specimenTransactions
              .map(e => `${e.cptDescription} (${e.quantity})`)
              .join(", ");
          } else {
            returnText = specimen[field].map(e => e[displayExpr]).join(", ");
          }
        }
        // Look up what the label will be
        const FieldNameEnum = {
          resultsMacros: "Macro",
          icdCodes: "ICD",
          specimenTransactions: "Tx"
        };
        textItems.push(`<b>${FieldNameEnum[field]}:</b> ${returnText}`);
      }
      // Create the HTML by joining the array with <br> tags
      return textItems.join("<br>");
    },
    refreshCaseSpecimens() {
      this.loadingText = { text: "Loading specimens...", class: "" };
      SpecimensApi.getSpecimen(this.reportCaseId)
        .then(res => {
          this.loadingText = { text: "", class: "" };
          this.caseSpecimens = res;
        })
        .catch(() => {
          this.loadingText = {
            text: "Error loading specimens",
            class: "text-danger font-weight-bold"
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-overlay-body {
  color: black;
  background-color: white;
  padding-left: 0.25em;
  padding-right: 0.5em;
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  border: 1px solid lightgray;
}
.close-button:hover {
  cursor: pointer;
}
</style>
