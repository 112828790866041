import { defaultUserSettings } from "@/modules/defaultUserSettings";
import { CasesModeEnum } from "@/modules/enums";
import { UsersApi } from "@/services";

export const initialEditors = {
  diagnosis: {
    height: 320,
    accessKey: "d",
    resultsOrder: 0
  },
  notes: {
    height: 320,
    accessKey: "n",
    label: "Specimen Notes",
    resultsOrder: 1
  },
  microscopic: {
    height: 320,
    accessKey: "m",
    resultsOrder: 2
  },
  gross: {
    height: 320,
    accessKey: "g",
    resultsOrder: 3,
    specimenOrder: 0
  },
  caseNotes: {
    height: 320,
    accessKey: "a",
    label: "Case Notes",
    resultsOrder: 4,
    specimenOrder: 1
  },
  clinical: {
    height: 320,
    accessKey: "c",
    resultsOrder: 5,
    specimenOrder: 0
  },
  synopticText: {
    height: 320,
    accessKey: "y",
    label: "Synoptic Text",
    resultsOrder: 6,
    specimenOrder: 2
  }
};
export const applicationSettings = {
  namespaced: true,
  state: () => ({
    ...defaultUserSettings,
    enableTWAINDriver: false,
    accessionMode: "demographics",
    accessionFilter: CasesModeEnum.Open,
    defaultLabelPrinter: "",
    defaultSpecLabelPrinter: "",
    defaultReqLabelPrinter: "",
    defaultSlidePrinter: "",
    defaultGlassSlidePrinter: "",
    defaultOrdersReportPrinter: "",
    defaultCassettePrinter: "",
    defaultWorksheetPrinter: "",
    defaultBatchPrinter: "",
    defaultHistoryPrinter: "",
    homeGridMode: 0,
    selectedTWAINDevice: null,
    useScannerFeeder: false,
    useDuplexScanning: false,
    selectedResolution: 0,
    twainMultiUploadType: 0,
    tagNotePrinter: "",
    pdfZoom: 75,
    accordionView: false,
    casesSearchHeader: {
      pathologists: [],
      casePrefixTags: [],
      holdCodes: [],
      procedures: [],
      tags: [],
      alertFilter: null
    },
    ignoreAllCapsWords: false,
    ignoreDomainNames: true,
    ignoreWordsWithMixedCases: false,
    ignoreWordsWithNumbers: false,
    userDictionaryName: "",
    quickLinksOrder: {
      openCard: "orders",
      items: [{ name: "holds" }, { name: "orders" }, { name: "tags" }, { name: "images" }]
    },
    textEditors: {
      diagnosis: {
        height: 320,
        accessKey: "d",
        resultsOrder: 0
      },
      notes: {
        height: 320,
        accessKey: "n",
        label: "Specimen Notes",
        resultsOrder: 1
      },
      microscopic: {
        height: 320,
        accessKey: "m",
        resultsOrder: 2
      },
      gross: {
        height: 320,
        accessKey: "g",
        resultsOrder: 3,
        specimenOrder: 0
      },
      caseNotes: {
        height: 320,
        accessKey: "a",
        label: "Case Notes",
        resultsOrder: 4,
        specimenOrder: 1
      },
      clinical: {
        height: 320,
        accessKey: "c",
        resultsOrder: 5,
        specimenOrder: 2
      },
      synopticText: {
        height: 320,
        accessKey: "y",
        resultsOrder: 6,
        specimenOrder: 2
      }
    }
  }),

  //! TODO REMOVE ALL MUTATIONS THAT ARE NOW BEING CONSOLIDATED INTO THE USER SETTINGS.
  mutations: {
    setAccessionFilter(state, payload) {
      state.accessionFilter = payload;
    },
    setImagePrintOnReport(state, payload) {
      state.imagePrintOnReport = payload;
    },
    setEnableTWAINDriver(state, payload) {
      state.enableTWAINDriver = payload;
    },
    setCloseAfterTwain(state, payload) {
      state.closeAfterTwain = !!payload;
    },
    setUseScannerFeeder(state, payload) {
      state.useScannerFeeder = !!payload;
    },
    setScannerDuplex(state, payload) {
      state.useDuplexScanning = payload;
    },
    setSelectedTWAINDevice(state, payload) {
      state.selectedTWAINDevice = payload;
    },
    setSelectedResolution(state, payload) {
      state.selectedResolution = payload;
    },
    setDefaultImageType(state, payload) {
      state.defaultImageType = payload;
    },
    setExpandDemo(state, payload) {
      state.expandDemo = payload;
    },
    setMacroSearchMode(state, payload) {
      state.macroSearchMode = payload;
    },
    setMacroStartsWith(state, payload) {
      state.macroStartsWith = payload;
    },
    setAccessionMode(state, payload) {
      state.accessionMode = payload;
    },

    setMacroAssist(state, payload) {
      state.macroAssist = payload;
    },
    setAppSettings(state, payload = {}) {
      for (const property in payload) {
        state[property] = payload[property];
      }
    },
    setAccordionView(state, payload) {
      state.accordionView = payload;
    },
    setGridMode(state, payload) {
      state.homeGridMode = payload;
    },
    setCaseSearchHeader(state, payload) {
      state.casesSearchHeader = { ...state.casesSearcHeader, ...payload };
    },
    setPdfZoom(state, payload) {
      state.pdfZoom = payload;
    },
    setDefaultSlidePrinter(state, payload) {
      state.defaultSlidePrinter = payload;
    },
    setDefaultGlassSlidePrinter(state, payload) {
      state.defaultGlassSlidePrinter = payload;
    },
    setDefaultOrdersReportPrinter(state, payload) {
      state.defaultOrdersReportPrinter = payload;
    },
    setDefaultLabelPrinter(state, payload) {
      state.defaultLabelPrinter = payload;
    },
    setDefaultSpecLabelPrinter(state, payload) {
      state.defaultSpecLabelPrinter = payload;
    },
    setDefaultReqLabelPrinter(state, payload) {
      state.defaultReqLabelPrinter = payload;
    },
    setDefaultWorksheetPrinter(state, payload) {
      state.defaultWorksheetPrinter = payload;
    },
    setDefaultCassettePrinter(state, payload) {
      state.defaultCassettePrinter = payload;
    },
    setDefaultBatchPrinter(state, payload) {
      state.defaultBatchPrinter = payload;
    },
    setDefaultHistoryPrinter(state, payload) {
      state.defaultHistoryPrinter = payload;
    },
    setPathReportLocation(state, payload) {
      state.pathReportLocation = payload;
    },
    setTagNotePrinter(state, payload) {
      state.tagNotePrinter = payload;
    },
    setAutomaticMacroPopup(state, payload) {
      state.automaticMacroPopup = payload;
    },
    setDefaultPrintMode(state, payload) {
      state.defaultPrintMode = payload;
    },
    setDefaultDashboardMode(state, payload) {
      state.defaultDasboardMode = payload;
    },
    setQuickLinksOrder(state, payload) {
      state.quickLinksOrder = payload;
    },
    setEditorsOrder(state, payload) {
      state.textEditors = payload;
    },
    setUserDictionaryName(state, payload) {
      state.userDictionaryName = payload;
    },
    setAutoOpenEditors(state, payload) {
      state.autoOpenEditors = payload;
    },
    setTwainMultiUploadType(state, payload) {
      state.twainMultiUploadType = payload;
    }
  },

  actions: {
    async setUserSettings({ commit, rootState }, payload) {
      const currentUserSettings = JSON.parse(rootState.currentUser.settings || "{}");
      const newUserSettings = { ...currentUserSettings, ...payload };
      if (Object.keys(newUserSettings).includes("enableTWAINDriver")) {
        delete newUserSettings.enableTWAINDriver;
      }
      await UsersApi.changeUserSettings(newUserSettings);
      commit("setCurrentUserSettings", JSON.stringify(newUserSettings), { root: true });
    }
  }
};
