<template>
  <div class="procedure-modal container">
    <dx-grid-with-search
      :noDataText="`No ${isTag ? 'tags' : 'notes'} found.`"
      ref="tagsGrid"
      :title="isTag ? 'Tags' : 'Notes'"
      :pageSize="10"
      @initialized="initGrid"
      :columns="columns"
      :dataSource="dataSource"
      :toolbar="toolbar"
      @content-ready="onContentReady"
      gridName="tagNote"
    >
      <template v-slot:extraActions>
        <div class="d-flex">
          <icon-button
            type="button"
            icon="print"
            class="btn btn-outline-primary mx-2"
            @click="printTagNotes"
          >
            Print
          </icon-button>
          <add-button
            type="button"
            @click="handleAddTags"
            v-if="permissions.CaseTagNoteCreateEdit"
          />
        </div>
      </template>
      <template v-slot:actions="{ data: { data: tag } }">
        <div class="d-flex justify-content-center align-items-center">
          <icon-button
            icon="pen-alt"
            class="text-primary pointer mr-2 p-0"
            role="button"
            v-tooltip.left="'Edit tag/note.'"
            @click="editTag(tag)"
          />
          <icon-button
            icon="trash-alt"
            class="text-danger pointer p-0"
            role="button"
            v-tooltip.left="'Remove tag/note.'"
            @click="removeTag(tag)"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isAdding" @close="closeForm">
      <div>
        <h3>{{ targetTag.id === undefined ? "Add" : "Edit" }} {{ isTag ? "tag" : "note" }}</h3>
        <form
          @submit.prevent="handleSubmit"
          class="px-4 bg-white order-form container"
          v-shortkey="saveShortkey"
          @shortkey="handleSubmit"
        >
          <div class="row">
            <SelectInput
              v-focus
              v-model="targetTag.specimenId"
              displayExpr="specimenOrder"
              label="Specimen"
              class="col specimen"
              :items="gridSpecimens.filter(e => e.id)"
            />

            <SelectInput
              v-focus
              v-model="targetTag.tagId"
              :displayExpr="tagNoteDisplayExpr"
              :label="isTag ? 'Tag' : 'Note'"
              :disabled="!!targetTag.id"
              class="col"
              :items="gridTagsNotes.filter(e => e.id)"
              :validator="$v.targetTag.tagId"
            />
          </div>
          <div class="row">
            <text-area-input
              class="col"
              label="Message"
              ref="targetTagText"
              id="desc"
              rows="5"
              cols="10"
              v-model="targetTag.text"
              :generalMacrosEnabled="true"
              :caseId="caseId"
              width="40vw"
              startHeight="25rem"
            />
          </div>

          <div class="row mt-2 px-3 justify-content-end">
            <div>
              <button @click="closeForm" type="button" class="btn btn-danger mx-2">Cancel</button>
              <button :disabled="isSubmitDisabled" type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import AddButton from "./common/AddButton.vue";
import Modal from "./common/Modal.vue";
import { ReportsApi, DropdownApi, MacrosApi } from "../services";
import TextAreaInput from "./TextAreaInput.vue";
import { mapGetters, mapState } from "vuex";
import IconButton from "./common/IconButton.vue";
import ArrayStore from "devextreme/data/array_store";
import SelectInput from "@/components/common/SelectInput.vue";
import { handleErrors } from "@/modules/handleErrors";
import moment from "moment";
import { altKey, createLocalTzDateFilter, formatDatetimeCell } from "@/modules/helpers";
import { format } from "date-fns";
import { MacroTypeEnum } from "@/modules/enums";
import { sortBy } from "lodash";

export default {
  name: "QuickLink-Tag-Popup",
  components: {
    DxGridWithSearch,
    AddButton,
    Modal,
    TextAreaInput,
    IconButton,
    SelectInput
  },
  data() {
    return {
      isAdding: false,
      reqLabels: 1,
      tagNotesList: [],
      tagsGrid: {},
      targetTag: {
        tagId: null,
        caseId: null,
        specimenId: null,
        text: "",
        type: ""
      },
      toolbar: {
        items: [{ location: "after", template: "extraActions" }]
      },
      isSubmtting: false,
      saveShortkey: altKey("s"),
      hasContentReadyHappened: false,
      isMacroOpen: false,
      generalMacros: [],
      positionToPlaceCursor: 0
    };
  },
  props: {
    isTag: Boolean,
    caseId: Number
  },
  created() {
    DropdownApi.getTags().then(res => {
      this.tagNotesList =
        sortBy(
          res.filter(
            e => (this.isTag ? e.settingType === "T" : e.settingType === "N") && this.isEffective(e)
          ),
          "tagCode"
        ) || [];
    });
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
    if (!Object.keys(this.caseDetails).length && this.caseId) {
      this.loadCaseFromProps();
    }
  },
  beforeDestroy() {
    if (this.caseId) {
      this.$store.commit("accessionStore/clearCaseDetails");
    }
  },
  validations() {
    return {
      targetTag: {
        tagId: {
          required: value => (value === 0 || value === null ? false : true)
        }
      }
    };
  },
  watch: {
    "targetTag.tagId"(nv) {
      const target = this.tagNotesList.find(e => e.id === nv);

      if (target) {
        this.targetTag.type = target.settingType;
      } else {
        this.targetTag.type = "";
      }
    }
  },
  methods: {
    initGrid({ component }) {
      this.tagsGrid = component;
    },
    handleAddTags() {
      this.isAdding = true;
    },
    async printTagNotes() {
      if (!this.tagNotePrinter) {
        window.alert("Please select a tag/note printer.");
        return;
      }
      const caseId = this.$route.params.caseId || this.caseId;
      let selectedItems = this.tagsGrid.getSelectedRowsData();
      if (selectedItems?.length === 0) {
        //If no items are selected, print all items
        selectedItems = this.listData;
      }
      function getTagIds(items) {
        return items.map(e => e.tagId);
      }
      function getCaseTagIds(items) {
        return items.map(e => e.id);
      }
      try {
        const response = await ReportsApi.printTagNoteTemplate({
          printerId: this.tagNotePrinter,
          labId: this.currentLab,
          caseId,
          numberOfcopies: 1,
          tagIds: getTagIds(selectedItems),
          caseTagIds: getCaseTagIds(selectedItems),
          tagType: this.isTag ? "T" : "N"
        });
        if (typeof response === "string" && /fail/i.test(response)) {
          window.notify(`Error printing ${this.isTag ? "tags" : "notes"}.`, "error");
        } else {
          window.notify(`Printed ${selectedItems.length} ${this.isTag ? "tags" : "notes"}`);
        }
      } catch (error) {
        handleErrors(error);
      }
    },
    async editTag(item) {
      if (!this.permissions.CaseTagNoteCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      this.targetTag = item;
      this.targetTag.tagId = item.tagId; // update tagId from typecode
      this.isAdding = true;
    },
    async removeTag(tag) {
      if (!this.permissions.CaseTagNoteCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      const confirm = await window.confirm(
        `Are you sure you want to delete this ${tag.type === "T" ? "tag" : "note"}?`
      );
      if (!confirm) {
        return;
      }
      this.$store.dispatch("accessionStore/removeQuickLink", {
        type: tag.type,
        caseId: this.$route.params.caseId || this.caseId,
        ids: [tag.id]
      });
    },
    closeForm() {
      this.tagsGrid.refresh(true);
      this.targetTag = {
        tagId: null,
        caseId: null,
        specimenId: null,
        text: "",
        type: ""
      };
      this.isAdding = false;
    },
    async handleSubmit() {
      if (this.isSubmitDisabled) {
        return;
      }
      this.isSubmtting = true;
      const caseId = this.$route.params.caseId || this.caseId;
      this.targetTag.caseId = caseId;
      try {
        await this.$store.dispatch("accessionStore/upsertCaseQuickLink", {
          ...this.targetTag,
          typeCode: { id: this.targetTag.tagId }
        });
        this.closeForm();
      } catch (error) {
        console.error(error);
        if (error?.response?.data) {
          window.notify(error?.response?.data, "error");
        } else {
          window.notify("Error occurred with your submission.", "error");
        }
      } finally {
        this.isSubmtting = false;
      }
    },
    isEffective({ expiryOn, effectiveOn }) {
      if (moment(expiryOn) < moment() || moment(effectiveOn) > moment()) {
        return false;
      }
      return true;
    },
    calculateLocalDate(date) {
      if (date) {
        const formattedDate = formatDatetimeCell(date);
        return format(formattedDate, "M/dd/yyyy");
      }
      return "";
    },
    onContentReady() {
      if (
        ((this.isTag && this.caseTags.length === 0) ||
          (!this.isTag && this.caseNotes.length === 0)) &&
        !this.disableAutoAddQuickLinks &&
        this.permissions.CaseTagNoteCreateEdit &&
        !this.hasContentReadyHappened
      ) {
        this.isAdding = true;
      }
      this.hasContentReadyHappened = true;
    },
    tagNoteDisplayExpr(data) {
      return this.TagNoteSearchByCode ? data?.tagCode : data?.displayName || "";
    }
  },
  computed: {
    ...mapState({
      tagNotePrinter: state => state.applicationSettings.tagNotePrinter,
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      caseTags: state => state.accessionStore.caseTags,
      caseNotes: state => state.accessionStore.caseNotes,
      currentUser: state => state.currentUser,
      disableAutoAddQuickLinks: state => state.applicationSettings.disableAutoAddQuickLinks,
      TagNoteSearchByCode: state => state.labSettings.TagNoteSearchByCode
    }),
    ...mapGetters(["permissions"]),
    listData() {
      return this.isTag ? this.caseTags : this.caseNotes;
    },
    dataSource() {
      return new ArrayStore({ data: this.listData, key: "id" });
    },
    gridSpecimens() {
      if (this.caseDetails?.specimens) {
        return [{ specimenOrder: "All", id: null }, ...this.caseDetails.specimens];
      }
      return [];
    },
    gridTagsNotes() {
      if (this.tagNotesList !== null && this.tagNotesList.length > 0) {
        return [{ displayName: "All", id: null }, ...this.tagNotesList];
      }
      return [];
    },
    columns() {
      return [
        {
          dataField: "addedBy"
        },
        { dataField: "updatedBy", dataType: "string", caption: "Edited By" },
        {
          dataField: "type",
          dataType: "string",
          lookup: {
            dataSource: {
              store: [
                { id: "T", displayName: "Tag" },
                { id: "N", displayName: "Note" }
              ]
            },
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "addedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.addedOn) {
              return this.calculateLocalDate(data.addedOn);
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "addedOn");
          },
          calculateSortValue: data => data.addedOn
        },
        {
          dataField: "updatedOn",
          dataType: "date",
          caption: "Edited On",
          calculateDisplayValue: data => {
            if (data.updatedOn) {
              return this.calculateLocalDate(data.updatedOn);
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "updatedOn");
          },
          calculateSortValue: data => data.updatedOn
        },
        {
          dataField: "tagId",
          caption: "Code",
          lookup: {
            dataSource: this.tagNotesList,
            valueExpr: "id",
            displayExpr: this.TagNoteSearchByCode ? "tagCode" : "displayName"
          }
        },
        {
          dataField: "specimenId",
          caption: "Specimen",
          lookup: {
            dataSource: { store: this.caseDetails?.specimens ?? [] },
            valueExpr: "id",
            displayExpr: "specimenOrder"
          }
        },
        {
          dataField: "text",
          caption: "Message",
          width: "15%",
          minWidth: 100
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          visible: this.permissions.CaseTagNoteCreateEdit
        }
      ];
    },
    isSubmitDisabled() {
      return this.$v.$invalid || this.isSubmtting || this.isEditLocked;
    },
    selectedType() {
      if (this.targetTag?.tagId) {
        const tag = this.tagNotesList.find(e => e.id === this.targetTag.tagId);
        if (tag) {
          return tag;
        }
      }
      return null;
    },
    isEditLocked() {
      if (this.targetTag?.addedBy && this.selectedType && this.selectedType?.tagEditableBy) {
        if (this.selectedType?.tagEditableBy) {
          const editLockType = parseInt(this.selectedType?.tagEditableBy);
          switch (editLockType) {
            case 0:
              // Anyone can edit
              return false;
            case 1:
              // Only user who created tag/note can edit
              return (
                this.targetTag.addedBy.toLowerCase() !== this.currentUser.userName.toLowerCase()
              );
            case 2:
              // Only user who created note or has permission to edit any tag
              return (
                this.targetTag.addedBy.toLowerCase() !== this.currentUser.userName.toLowerCase() &&
                !this.permissions.CaseTagNoteEditAny
              );
          }
        }
      }
      return false;
    }
  }
};
</script>

<style>
.macro-popup {
  max-width: 100% !important;
}
</style>
